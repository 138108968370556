import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";  // Adjust the path accordingly.
import { BASE_URL } from '../../../constants';
import DrorLoader from '../../charts/drorLoader/DrorLoader';

const ResetPasswordPage = ({handleLogin}) => {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState("");
    const [error, setError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [resetEmail, setResetEmail] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);


    const handleResetEmailChange = (e) => {
        setResetEmail(e.target.value);
        setError("")
    };
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };


    const handleSendResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
        if (!passwordRegex.test(password)) {
            setError('עליך לבחור סיסמה בעלת 8 תווים, הכוללת מספרים, אותיות ואות גדולה אחת לפחות');
            return;
        }
        
        if (password !== confirmPassword) {
            setError('הסיסמה שלך אינה תואמת');
            setError(true);
        }

        try {
            const response = await axios.post(`/accounts/reset-password/${uidb64}/${token}/`, { password });
            Swal.fire({
              title: 'האיפוס הושלם בהצלחה',
              text: '',
              icon: 'success',
              confirmButtonText: 'סגור',
            }).then(() => {
                setPageLoading(true)
                window.location.href = '/';
            });
        } catch (error) {
            Swal.fire({
              title: 'שגיאה',
              text: '',
              icon: 'error',
              confirmButtonText: 'סגור',
            });
        }

        setLoading(false);
    };

    return (
        <div dir="rtl" className="flex h-[97vh] items-center justify-center px-4">
            {pageLoading ? <DrorLoader /> :
                <div style={{backgroundColor: '#1f1f1f', maxWidth: 450, fontFamily: "open sans hebrew"}}
                    className="p-8 w-[100%] shadow-md rounded-md rounded-xl text-white">
                    <h2 className="text-2xl text-center font-bold mb-6">איפוס סיסמה</h2>
                        {/* <p className="mb-4">שכחת את הסיסמה?</p>
                        <p className="mb-4">הזן את כתובת המייל באמצעותה נרשמת לאתר ואנו נשלח לך מייל מאובטח לשינוי
                            הסיסמה</p> */}
                        
                        <div className="mb-4">
                            <label htmlFor="password" className="block  text-sm font-bold mb-2">
                                סיסמה
                            </label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="w-full p-2 rounded-md text_box_color"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                {/* <button
                                    type="button"
                                    className="absolute top-2 left-4 text-gray-500"
                                    // onClick={handleTogglePasswordVisibility}34.32.129.212
                                >
                                    {showPassword ? 'הסתר' : 'הצג'}
                                </button> */}
                            </div>
                        </div>
                            
                        <div className="mb-4">
                            <label htmlFor="password" className="block  text-sm font-bold mb-2">
                                אשר סיסמה
                            </label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="w-full p-2  rounded-md text_box_color"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                {/* <button
                                    type="button"
                                    className="absolute top-2 left-4 text-gray-500"
                                    // onClick={handleTogglePasswordVisibility}
                                >
                                    {showPassword ? 'הסתר' : 'הצג'}
                                </button> */}
                            </div>
                        </div>
                        {error && <p className="text-white mb-4">{error}</p>}
                        <button
                            type="button"
                            onClick={handleSendResetPassword}
                            className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
                        >
                            איפוס
                        </button>
                </div>
            }
        </div>
    );
};

export default ResetPasswordPage;
