import React, { useEffect, useRef, useState } from "react";
import { FaFileExcel } from "react-icons/fa";


const Dropdown = ({ 
  chartType,
  num_multi_search,
  grandSum,
  selected_data_column,
  secType,
  checkDisableDropdown,
  handleCustomDropdownTop,
}) => {
  
const dropdownList = [
  {
    id: 1,
    value: "שווי שוק",
    /*Market Cap*/
  },
  // Holder F charts do not have par percentage
  ...(chartType === 'f_holder' ? [] : [{
    id: 2,
    value: "אחוז מהון רשום",
    /*0% of capital*/
  }]),
  {
    id: 3,
    value: "ערך נקוב",
    /*Par Value*/
  }
];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState("שווי שוק");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectRef = useRef(null);
  

  useEffect(() => {
    document.addEventListener('mousedown', customBlur, { capture: true });
  }, [])
  

  const customBlur = (e) => {
    if (
      e.target === selectRef.current || selectRef.current?.contains(e.target)
    ) {
    } else {
      setIsDropdownOpen(false);
    }
    document.addEventListener('mousedown', customBlur, { capture: true });
  }

  const handleSelect = (value) => {
    setCurrentValue(value);
    handleCustomDropdownTop(value);
    setIsDropdownOpen(false);
  }
  
  return (  
    <div className="h-[45px] rounded bg-[#292E32] w-full select-wrapper z-[12]"
      onClick={toggleDropdown}
      ref={selectRef}
    >
      <div className="text-center px-2 text-white relative">
        <span className="dropdown-icon"></span>
        <a
            dir="rtl"
            id="selectt"
            className=" bg-transparent border-2 hover:bg-transparent p-0 font-bold"
            style={{
                margin: 0,
                paddingRight: 0,
                paddingBottom: 0,
                border: 0,
                outline: "none",
                fontSize: 14,
                width: "100%"
            }}
        >
          <p className="text-right">{currentValue}</p>
            <p style={{
                position: "relative",
                fontSize: 16,
                fontWeight: "bold"
            }} className="text-right heb font-semibold mb-0">
                {
                  num_multi_search === 1 ?
                  <b>{grandSum}{selected_data_column === "sum_par_percentage" ? "%" : ""}</b> :
                  "0"
                }
            </p>
        </a>
      </div>
      {/* {console.log('From the dropdown -- ', secType, checkDisableDropdown(secType))} */}
      {isDropdownOpen && (
        <ul dir="rtl" className="absolute w-[100%] z-[1]">
          {dropdownList.map(item => (
            <li key={item.id}>
              <button 
                // disabled={checkDisableDropdown(secType)}
                className={`dropdown-content menu w-[100%] p-2 shadow bg-black text-right ${currentValue === item.value ? "active" : ""}`}
                onClick={() => handleSelect(item.value)}
                onBlur={() => {console.log('onblurr')}}
              >
                <span>{item.value}</span>
              </button>
            </li>
          ))}
          {/* <button
            className="dropdown-content w-[100%] p-2 shadow bg-black text-right flex items-center justify-between"
          >
            <span>הורד הכל לאקסל</span>
            <FaFileExcel />
          </button> */}
        </ul>
      )}
    </div>
  )
};

export default Dropdown;
