import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";  // Adjust the path accordingly.
import { BASE_URL } from '../../../constants';
import DrorLoader from '../../charts/drorLoader/DrorLoader';

const ActivationPage = () => {
    const { uidb64, token } = useParams();
    const [pageLoading, setPageLoading] = useState(true);
    const navigate = useNavigate();


    const handleActivateEmail = async (e) => {
        try {
            await axios.get(`/accounts/activate/${uidb64}/${token}/`);
            Swal.fire({
            //   title: 'הצלחה',
              text: 'החשבון הופעל בהצלחה',
              icon: 'success',
              confirmButtonText: 'סגור',
            }).then(() => {
                setPageLoading(false)
                navigate('/')
            });
        } catch (error) {
            Swal.fire({
              title: 'הפעלה נכשלה',
              //   text: 'שגיאה',
              icon: 'error',
              confirmButtonText: 'סגור',
            }).then(() => {
                setPageLoading(false)
                navigate('/')
            });
        }
    };

    useEffect(() => {
        handleActivateEmail();
    }, [token]);

    return (
        <div dir="rtl" className="flex h-[97vh] items-center justify-center px-4">
            {pageLoading ? <DrorLoader /> :
                <div></div>
            }
        </div>
    );
};

export default ActivationPage;
