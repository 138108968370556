import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import DrorLoader from "../charts/drorLoader/DrorLoader";
import {FaTimes} from "react-icons/fa";
import MultiRangeSlider from "./components/multiRangeSlider/MultiRangeSlider"
import MultiRangeSliderSmall from "./components/multiRangeSlider/MultiRangeSliderSmall"
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {IoCloseOutline, IoPlaySkipForward} from "react-icons/io5";
import axios from 'axios';
import Charts from "../charts";
import "./rangeslider.css"
import {formatNumber} from "../utils"
import Swal from 'sweetalert2'
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import { BASE_URL } from "../../constants";
import Faqs from "./faqs";
import SearchHistory from "./SearchHistory";
import RelativeSearchDropdown from "./RelativeSearchDropdown";
import Notification from "../common/Notification";
import Contact from "./contact";
import SearchComponent from "./searchcomponent";
import Alerts from "./alerts";
import SearchModal from "./searchModal";

const Search = ({ isMobile, contactOpen, setContactOpen, faqOpen, setFaqOpen, alertOpen, setAlertOpen, handle_logout }) => {
        const [activeClass, setActiveClass] = useState("hidden");
        // let activeClass = "hidden"

        const cancel = (e) => {
            e.preventDefault();
            setActiveClass("hidden");

        };
        const toggleSearch = () => {
            // // console.log("toggle 1 triggerd")
            setActiveClass(activeClass === "hidden" ? "block" : "hidden");
        };

        function toggleSearch2(myclass) {
            // console.log("toggle 2 triggerd")
            setActiveClass(myclass);
        };

        // 3 TYPES OF CHARTS (QUARTERLY, CONTINUOUS, F_HOLDER)
        const [chartType, setChartType] = useState('quarterly');
        const relativeCorpRef = useRef(null);
        
        const [visibleSearchPopup, setVisibleSearchPopup] = useState(false);
        const [visibleSuggestions, setVisibleSuggestions] = useState(false);

        const [searchedTerms, setSearchedTerms] = useState([]);

        const [lastField, setLastField] = useState(false);

        const [lastSearches, setLastSearches] = useState([]);
        const [favSearches, setFavSearches] = useState([]);

        const [searchSuggestions, setSearchSuggestions] = useState([]);
        const [labelsDataNum, setLabelsDataNum] = useState(670)
        const [relatedCorpsIds, setRelatedCorpsIds] = useState([])
        const [relatedCorpIdSearchIndex, setRelatedCorpIdSearchIndex] = useState();
        const [showModal, setShowModal] = useState(false);

        const [secTypeSub, setSecTypeSub] = useState([])
        const [secType, setSecType] = useState([])
        const [secId, setSecId] = useState([])
        const [sector, setSector] = useState([])
        const [sectorSub, setSectorSub] = useState([])

        // NEW QUERY variable (Exists in MS_FREQ_UPDATE) 
        const [existsInMsFreq, setExistsInMsFreq] = useState([]);
        //  Right chart data
        const [seriesData, setSeries] = useState([])

        // Table data
        const [labelsData, setLabelsData] = useState([])
        const [allLabelsNames, setAllLabelsNames] = useState({})
        const [allQuarterNames, setAllQuarterNames] = useState({})

        // Left Charts Data
        const [barMonthlyData, setbarMonthlyData] = useState([])
        const [barQuarterlyData, setbarQuarterlyData] = useState([])
        const [barMonthlyCategories, setbarMonthlyCategories] = useState([])
        const [barQuarterlyCategories, setbarQuarterlyCategories] = useState([])


        const [grandSum, setGrandSum] = useState(0)
        const [selectedRowId, setSelectedRowId] = useState('none');
        const [display, setDisplay] = useState(false);
        const [selectedDataColumn, setSelectedDataColumn] = useState("sum_market_cap")

        const [dates, setDates] = useState({
            "quarterly" : {'months': [], 'quarters': []}, 
            "continuous" : {'months': [], 'quarters': []}, 
            "f_holder": {'months': [], 'quarters': []}
        })
        const [datesForFHolder, setDatesForFHolder] = useState({'months': [], 'quarters': []})
        const [valuesForMonthsAndQuarters, setValuesForMonthsAndQuarters] = useState([1, 1]);
        const [relatedSecuritySelectedValues, setRelatedSecuritySelectedValues] = React.useState([]);
        const [checkedValuesMonths, setCheckedValuesMonths] = React.useState([true, false, false, false, false, false, false, false, false, false, false, false]);
        const [checkedValuesQuarter, setCheckedValuesQuarter] = React.useState([true, false, false, false, false, false, false, false, false, false, false, false]);
        const checkedValuesFalse = [false, false, false, false, false, false, false, false, false, false, false, false];
        const checkedValuesDefault = [true, false, false, false, false, false, false, false, false, false, false, false];
        const [lagends, setLagends] = React.useState([]);
        const [num_quarters, setNumQuarters] = React.useState(1);
        const [loading, setLoading] = useState(false);
        const [loadingLeft, setLoadingLeft] = useState(false);
        const [customDropdown, setCustomDropdown] = useState(false);
        const [keywords, setKeywords] = useState([])
        const [rightChartCatagories, setRightChartCatagories] = useState([])
        const [column, setColumn] = useState("sec_id")
        const [column2, setColumn2] = useState("holders")
        const [colTab2, setColTab2] = useState("כותרת")
        const [onHoverCap, setOnHoverCap] = useState(false)
        const [onHoverDur, setOnHoverDur] = useState(false)
        const oneOrTwo = '1'
        const [toShowDuration, setToShowDuration] = useState(true)
        const [toShowCap, setToShowCap] = useState(true)
        const [currentFavouriteKey, setCurrentFavouriteKey] = useState(-1)
        const [starActive, setStarActive] = useState(false)
        const [filters, setFilters] = useState({
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'rating': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        })
        const filters_start = {
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'rating': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        }
        const [customFilters, setCustomFilters] = useState({
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'foreign_rate': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        })

        const custom_filters_start = {
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'foreign_rate': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        }

        const [selectedCustomFilters, setSelectedCustomFilters] = useState({
            'sec_type_sub': null,
            'sector_sub': null,
            'market_cap_range': null,
            's_duration_range': null,
        })
        const modal_description = {
            'holders': ['באפשרותך לחפש קבוצות של ני”ע על פי פרמטרים נבחרים', 'חיפוש מתקדם', ''],
            'non_holders': ['רשימת מנהלים אשר מחזיקים בני”ע דומים לני”ע הנבחר אך אינם מחזיקים בנייר הנבחר', 'שאינם מחזיקים', 'ני”ע דומים על פי הרשימה מטה'],
            'holders_similar': ['אחזקות מצרפיות עבור כל המנהלים אשר מחזיקים בני”ע דומים לני”ע הנבחר כולל הני”ע הנבחר', 'אחזקות בני”ע דומים', 'ני”ע דומים על פי הרשימה מטה'],
            'sectors': ['רשימת ני”ע אשר דומים לני”ע הנבחר כולל הני”ע הנבחר', 'רשימת ני”ע דומים', 'ני”ע דומים על פי הרשימה מטה']
        }

        const [isCustomFilter, setIsCustomFilter] = useState(true)
        const [secIdDataList, setSecIdDataList] = useState([])

        const [relatedSecurities, setRelatedSecurities] = useState([])
        const [related_securities_legend, set_related_securities_legend] = useState({
            'related_securities_sector_sub_legend': {},
            'related_securities_sector_legend': {},
            'related_securities_sec_type_sub_legend': {},
            'related_securities_sec_type_legend': {}
        })

        const desc = 'באפשרותך לחפש קבוצות של ני”ע על פי פרמטרים נבחרים'
        const desc_ = ''
        const title_ = 'חיפוש מתקדם'
        const [description, setDescription] = useState(desc)
        const [description_, setDescription_] = useState(desc_)
        const [title_modal, setTitleModal] = useState(title_)
        const [left_chart_msg, setLeftChartMsg] = useState(false)
        const [appLoading, setAppLoading] = useState(true);
        const [notification, setNotification] = useState(null);
        const [errorNotification, setErrorNotification] = useState(null);


        const applyFilters = () => {
            // console.log(filters)
            console.log('THE RIGHT CHART DATA')
            if (secTypeSub?.length !== 0) {
                fetchRightChartData(secId, secTypeSub, selectedDataColumn).then(r => {
                    setActiveClass('hidden')
                })
            }

        }

        const setrangecap = (min, max) => {
            if (min !== filters['market_cap_range'][1] || max !== filters['market_cap_range'][2]) {
                setFilters({
                    ...filters,
                    'market_cap_range': [filters['market_cap_range'][0], min, max, filters['market_cap_range'][3]]
                })

            }
        }

        const setrangecap_custom = (min, max) => {
            if (min !== customFilters['market_cap_range'][1] || max !== customFilters['market_cap_range'][2]) {
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': [customFilters['market_cap_range'][0], min, max, customFilters['market_cap_range'][3]]
                })

            }
        }

        const setrangeduration = (min, max) => {
            if (min !== filters['s_duration_range'][1] || max !== filters['s_duration_range'][2]) {
                setFilters({
                    ...filters,
                    's_duration_range': [filters['s_duration_range'][0], min, max, filters['s_duration_range'][3]]
                })
            }
        }

        const handleCheckMonth = (event) => {
            const {value, checked} = event.target;
            let ls_qua = [...checkedValuesQuarter]
            let ls_mon = [...checkedValuesMonths]
            ls_mon[value] = checked

            if (countTrueValues(ls_mon, 2)) {
                if (countTrueValues(checkedValuesQuarter, 0)) {
                    ls_qua = checkedValuesFalse

                }
            } else {
                ls_qua = checkedValuesDefault
                ls_mon = checkedValuesDefault
            }
            setCheckedValuesQuarter(ls_qua)
            setCheckedValuesMonths(ls_mon)

            // Record no. of checks for months & quarters in an array
            setValuesForMonthsAndQuarters([
                ls_mon.filter(value => value === true).length,
                ls_qua.filter(value => value === true).length,
            ]);
        };

        const handleCheckQuarter = (event) => {
            const {value, checked} = event.target;
            let ls_qua = [...checkedValuesQuarter]
            let ls_mon = [...checkedValuesMonths]
            ls_qua[value] = checked

            if (countTrueValues(ls_qua, 2)) {
                if (countTrueValues(ls_mon, 0)) {
                    ls_mon = checkedValuesFalse
                }
            } else {
                ls_qua = checkedValuesDefault
                ls_mon = checkedValuesDefault
            }
            setCheckedValuesQuarter(ls_qua)
            setCheckedValuesMonths(ls_mon)

            // Record no. of checks for months & quarters in an array
            setValuesForMonthsAndQuarters([
                ls_mon.filter(value => value === true).length,
                ls_qua.filter(value => value === true).length,
            ]);

        };

        const handleFilterMonthsAndQuarters = () => {
            if (chartType === 'quarterly') {
                if (secTypeSub?.length !== 0 && (
                    (valuesForMonthsAndQuarters[0] + valuesForMonthsAndQuarters[1] >= 2) &&
                    (valuesForMonthsAndQuarters[0] + valuesForMonthsAndQuarters[1] <= 5))) 
                {
                    fetchRightChartData(secId, secTypeSub, selectedDataColumn, checkedValuesMonths, checkedValuesQuarter).then(r => {
                    })
                }
                setColumn2('holders')
                setColTab2('holders')
                handleCustomDropdown()
            }
            if (chartType === 'f_holder') {
                if (secTypeSub?.length !== 0 && (
                    (valuesForMonthsAndQuarters[1] >= 1) &&
                    (valuesForMonthsAndQuarters[1] <= 5))) 
                {
                    fetchRightChartDataForFHolder(secId, secTypeSub, selectedDataColumn, checkedValuesQuarter).then(r => {
                    })
                }
                handleCustomDropdown()
            }
        }

        const countTrueValues = (list, num = 0) => {

            let count = 0;
            for (let i = 0; i < list?.length; i++) {
                if (list[i] === true) {
                    count++;
                }
            }
            if (count >= num) {
                return true;
            } else {
                return false
            }

        }
        const fetchDataInit = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/get_dates`);
                // setDates({'months': response.data.months, 'quarters': response.data.quarters});
                setDates(response.data);
                    
                const response_filters = await axios.get(`${BASE_URL}/api/custom_filters/`);
                setCustomFilters(
                    {
                        ...customFilters,
                        'sec_type_sub': response_filters.data.sec_type_sub,
                        'sec_type_sub_legend': response_filters.data.sec_type_sub_legend
                    }
                )

                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                setNewFavoriteSearches(existing_storage)
                setIsCustomFilter(true)
            } catch (error) {
                console.log('Error fetching data:', error);
            } finally {
                setAppLoading(false); // Finish loading regardless of outcome
            }
        };

        useEffect(() => {
            fetchDataInit();
    
            // Get the last searches
            const item = localStorage.getItem('lastSearches');
            if (item === null) {
                localStorage.setItem('lastSearches', JSON.stringify([]));
            } else {
                try {
                    const lastSearches = JSON.parse(item);
                    if (Array.isArray(lastSearches) && lastSearches.length !== 0) {
                        setLastSearches(lastSearches);
                    }
                } catch (error) {
                    console.log("Failed to parse last searches from localStorage", error);
                    // Reset the localStorage item if parsing fails
                    localStorage.setItem('lastSearches', JSON.stringify([]));
                }
            }
        }, []);
        
        useEffect(() => {
            console.log('THE SEC ID & TERMS - ', secId, searchedTerms)
        }, [secId, searchedTerms])

        useEffect(() => {
            localStorage.setItem('lastSearches', JSON.stringify(lastSearches));
        }, [lastSearches])

        useEffect(() => {
            console.log('Visibility - ', visibleSearchPopup, visibleSuggestions);
        }, [visibleSearchPopup, visibleSuggestions])

        // Updates the length of RelativeCorpsId based on screen size
        const [visibleRelativeCorpIdCount, setVisibleRelativeCorpIdCount] = useState(30);
        
        useEffect(() => {
        }, [visibleRelativeCorpIdCount]);

        useEffect(() => {
          const calculatePSizes = () => {
            let otherOffsets = 70 + 206; // Width of dropdown arrow & container padding (left & right)
            let windowWidth = window.innerWidth;
            let perCharWidth = 6.8;
      
            let currentCount = 0;
            let currentIndex = 0
            for (let i = 0; i < relatedCorpsIds.length; i++) {
                const el = relatedCorpsIds[i];
                if (currentCount + otherOffsets > windowWidth) {
                    // console.log('THE NUMBER WE LO0KING ', currentIndex);
                    setVisibleRelativeCorpIdCount(currentIndex);
                    // console.log('the details ', windowWidth, currentCount)
                    return;
                }
                currentCount += (el[0]?.length * perCharWidth) + 20;
                currentIndex = i;
                // console.log('counting - ', el[0]?.length, currentCount, currentIndex)
            }
          };
      
          const handleResize = () => {
            calculatePSizes();
          };
      
          window.addEventListener('resize', handleResize);
          // Initial calculation
          calculatePSizes();
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [relatedCorpsIds]);


        
        // RELATED SECURITY POPUP LOGIC START
        // HANDLING SEARCH POPUP FOR RELATED SECURITIES
        const handleAddRelatedSecurity = (index) => {
            setRelatedSecuritySelectedValues(prevValues => {
                const newValues = [...prevValues];  // Create a copy of the current state
                newValues[index] = !newValues[index];  // Toggle the value at the specific index
                return newValues;  // Return the updated array
            });
        }
        const handleSearchRelatedSecurity = () => {
            let newSecIds = [...secId]
            let newSecTypeSubs = [...secTypeSub]
            let newKeywords = [...keywords]
            let newSearchedTerms = [...searchedTerms]
            for (let index = 0; index < relatedSecuritySelectedValues.length; index++) {
                if (relatedSecuritySelectedValues[index] === true) {
                    newSecIds.push(relatedSecurities[0][index][0]);
                    newKeywords.push(relatedSecurities[0][index][1]);
                    newSecTypeSubs.push(relatedSecurities[0][index][5]);
                    newSearchedTerms.push([
                        relatedSecurities[0][index][1],
                        relatedSecurities[0][index][0],
                        relatedSecurities[0][index][5],
                        relatedSecurities[0][index][4],
                        relatedSecurities[0][index][6],
                        relatedSecurities[0][index][7],
                    ])
                }
            }
            // console.log('THE NEW ARRAY - ', newSearchedTerms, newSecIds, newSecTypeSubs)
            setSecId(newSecIds);
            setSecTypeSub(newSecTypeSubs);
            setSearchedTerms(newSearchedTerms?.reverse());
            setKeywords(newKeywords);
            setLastField(!lastField);
            setVisibleSearchPopup(!visibleSearchPopup);

            // console.log('searching related - ', newSecIds, newSecTypeSubs, searchedTerms)
            fetchRightChartData(newSecIds, newSecTypeSubs, selectedDataColumn, checkedValuesDefault, checkedValuesDefault, newKeywords).then(r => {
                // setSearchedTerms(newSearchedTerms);
            })
        }
        useEffect(() => {
            if (relatedSecurities && relatedSecurities[0] !== undefined) {
                let newArray = new Array(relatedSecurities[0].length).fill(false);
                setRelatedSecuritySelectedValues(newArray);
            }
        }, [relatedSecurities])
        // RELATED SECURITY POPUP LOGIC END
        
        // useEffect(() => {
        //     console.log('the checked vals - ', relatedSecuritySelectedValues)
        // }, [relatedSecuritySelectedValues])

        // useEffect(() => {
        //     if (searchedTerms?.length >= 1) {
        //         if (searchedTerms[0][3] === '1') {
        //             const filteredSuggestions = suggestions.filter(item => item[3] !== '0' && item[3] !== '15');
                    
        //         }
        //     }
        // }, [searchSuggestions, searchedTerms])


        function filter_change(e, witch_select) {

            if (witch_select === 'sec_type_sub') {
                // console.log(witch_select)
                // console.log(e.target.value)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'sec_type_sub': e.target.value,
                    'sector_sub': null,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'atzmada': null,
                    'foreign_rate': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setDisplay(false)
                fetch_filters(updated_filters).then(r => {
                    console.log("Fetch filters is Ok")
                })
            } else if (witch_select === 'sector_sub') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'sector_sub': e.target.value,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'atzmada': null,
                    'foreign_rate': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            } else if (witch_select === 'atzmada') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'atzmada': e.target.value,
                    'foreign_rate': null,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            } else if (witch_select === 'foreign_rate') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'foreign_rate': e.target.value,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            }

        }

        function custom_filters_onclick() {
            setDisplay(false)
            setLoading(true)
            const updated_filters = {
                ...selectedCustomFilters,
                'market_cap_range': customFilters.market_cap_range,
                's_duration_range': customFilters.s_duration_range,
                'column': colTab2
            }
            setSelectedCustomFilters(updated_filters)
            fetch_filters_and_display(updated_filters).then(r => {
                // console.log("CUSTOM FILTERS")
            })
            toggleSearch2('hidden')

        }

        const fetch_filters_and_display = async (custom_filters = selectedCustomFilters) => {
            let months = []
            let quarters = []
            checkedValuesMonths.forEach(function (value, index) {
                if (value) {
                    months.push(dates["quarterly"]['months'][index][0])

                }
            });
            checkedValuesQuarter.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates["quarterly"]['quarters'][index][0])

                }
            });
            if (quarters?.length === 0 && months?.length === 0) {
                quarters.push(dates["quarterly"]['quarters'][0][0])
                months.push(dates["quarterly"]['months'][0][0])
            } else if (quarters?.length === 1 && months?.length === 0) {
                months.push(dates["quarterly"]['months'][0][0])
            }
            custom_filters['dates_q'] = quarters
            custom_filters['dates_m'] = months
            setSelectedCustomFilters(custom_filters)
            const response_filters = await axios.post(`${BASE_URL}/api/custom_filters/`, custom_filters);
            setCustomFilters({
                ...customFilters,
                'sec_type_sub': response_filters.data.sec_type_sub,
                'sector_sub': response_filters.data.sector_sub,
                'market_cap_range': response_filters.data.market_cap_range,
                's_duration_range': response_filters.data.s_duration_range,
                'atzmada': response_filters.data.atzmada,
                'foreign_rate': response_filters.data.foreign_rate,
                'sector_sub_legend': response_filters.data.sector_sub_legend,
                'atzmada_legend': response_filters.data.atzmada_legend,
                'sec_type_sub_legend': response_filters.data.sec_type_sub_legend,
                'foreign_rate_legend': response_filters.data.foreign_rate_legend,
            })
            if ('series_sum' in response_filters.data) {
                console.log('the COLUMN 2 from filters - ', response_filters.data.column2);
                if (response_filters.data.series?.length !== 0) {
                    setSeries(response_filters.data.series)
                    let count = custom_filters['dates_q']?.length
                    if (custom_filters['dates_q']?.length > 1) {
                        count = custom_filters['dates_q']?.length + 1
                    } else if (custom_filters['dates_m']?.length > 1) {
                        count = custom_filters['dates_m']?.length + 1
                    } else {
                        count = 1
                    }
                    
                    
                    // THIS IS TO OFFSET FOR THE LEGENDS & X-AXIS LABELS
                    let calculatedHeight = response_filters.data.series_sum?.length * 31 + 110;

                    if (column2 !== "sectors" || secType[0] !== "0" || secType[0] !== "15") {
                        calculatedHeight = response_filters.data.series_sum?.length * 31 + 110 + 31
                    }

                    if (count > 1) {
                        calculatedHeight = (response_filters.data.series_sum?.length * count * 20) + (response_filters.data.series_sum?.length * 11) + 100 + ((count - 1) * 10);
                    }
                    setLabelsDataNum(calculatedHeight);
                }

                setLabelsData(response_filters.data.series_sum)
                setAllLabelsNames(response_filters.data.all_labels_names)
                setAllQuarterNames(response_filters.data.list_quarter_names)
                setGrandSum(response_filters.data.all_values_sum)
                setLagends(response_filters.data.legands)
                setNumQuarters(custom_filters['dates_q']?.length)
                setRelatedCorpsIds(response_filters.data.related_corps_ids)
                setSearchSuggestions([]);
                setRightChartCatagories(response_filters.data.series_sum.map((row, ind) => (response_filters.data.all_labels_names[row[0]])))
                setColumn(column)
                setSelectedDataColumn(selectedDataColumn)
                setCheckedValuesMonths(checkedValuesMonths)
                setCheckedValuesQuarter(checkedValuesQuarter)
                setKeywords(keywords)
                setSecType([''])
                setSecId([''])
                setSecTypeSub([custom_filters['sec_type_sub']])
                setSectorSub([custom_filters['sector_sub']])
                setToShowDuration(true)
                setToShowCap(true)
                setColumn2(response_filters.data.column2)
                setColTab2(response_filters.data.column2)
                setDisplay(true)
                setLoading(false)
            } else {
                setLoading(false)
            }
            if ('is_data' in response_filters.data) {
                if (response_filters.data.is_data === null) {
                    setLoading(false)
                    setDisplay(false)
                    // setCustomFilters(custom_filters_start)
                    Swal.fire({
                        title: 'מידע',
                        text: 'אין היסטוריה עבור החיפוש המבוקש',
                        icon: 'info',
                        // confirmButtonText: 'Close'
                        confirmButtonText: 'סגור'
                    })
                }
            }
            console.log(response_filters.data)
        }

        const fetch_filters = async (custom_filters = selectedCustomFilters) => {
            let months = []
            let quarters = []
            checkedValuesMonths.forEach(function (value, index) {
                if (value) {
                    months.push(dates["quarterly"]['months'][index][0])

                }
            });
            checkedValuesQuarter.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates["quarterly"]['quarters'][index][0])

                }
            });
            if (quarters?.length === 0 && months?.length === 0) {
                quarters.push(dates["quarterly"]['quarters'][0][0])
                months.push(dates["quarterly"]['months'][0][0])
            } else if (quarters?.length === 1 && months?.length === 0) {
                months.push(dates["quarterly"]['months'][0][0])
            }
            custom_filters['dates_q'] = quarters
            custom_filters['dates_m'] = months
            setSelectedCustomFilters(custom_filters)
            const response_filters = await axios.post(`${BASE_URL}/api/custom_filters/`, custom_filters);
            setCustomFilters({
                ...customFilters,
                'sec_type_sub': response_filters.data.sec_type_sub,
                'sector_sub': response_filters.data.sector_sub,
                'market_cap_range': response_filters.data.market_cap_range,
                's_duration_range': response_filters.data.s_duration_range,
                'atzmada': response_filters.data.atzmada,
                'foreign_rate': response_filters.data.foreign_rate,
                'sector_sub_legend': response_filters.data.sector_sub_legend,
                'atzmada_legend': response_filters.data.atzmada_legend,
                'sec_type_sub_legend': response_filters.data.sec_type_sub_legend,
                'foreign_rate_legend': response_filters.data.foreign_rate_legend,
            })
            if ('series_sum' in response_filters.data) {
                if (response_filters.data.series?.length !== 0) {
                    setSeries(response_filters.data.series)
                    let count = custom_filters['dates_q']?.length
                    if (custom_filters['dates_q']?.length > 1) {
                        count = custom_filters['dates_q']?.length + 1
                    } else if (custom_filters['dates_m']?.length > 1) {
                        count = custom_filters['dates_m']?.length + 1
                    } else {
                        count = 1
                    }
                    // THIS IS TO OFFSET FOR THE LEGENDS & X-AXIS LABELS
                    let calculatedHeight = response_filters.data.series_sum?.length * 31 + 110;

                    if (column2 !== "sectors" || secType[0] !== "0" || secType[0] !== "15") {
                        calculatedHeight = response_filters.data.series_sum?.length * 31 + 110 + 31
                    }
                    if (count > 1) {
                        calculatedHeight = (response_filters.data.series_sum?.length * count * 20) + (response_filters.data.series_sum?.length * 11) + 100 + ((count - 1) * 10);
                    }
                    setLabelsDataNum(calculatedHeight);
                }
                setLabelsData(response_filters.data.series_sum)
                setAllLabelsNames(response_filters.data.all_labels_names)
                setAllQuarterNames(response_filters.data.list_quarter_names)
                setGrandSum(response_filters.data.all_values_sum)
                setLagends(response_filters.data.legands)
                setNumQuarters(custom_filters['dates_q']?.length)
                setRelatedCorpsIds(response_filters.data.related_corps_ids)
                setSearchSuggestions([]);
                setRightChartCatagories(response_filters.data.series_sum.map((row, ind) => (response_filters.data.all_labels_names[row[0]])))
                setColumn(column)
                setSelectedDataColumn(selectedDataColumn)
                setCheckedValuesMonths(checkedValuesMonths)
                setCheckedValuesQuarter(checkedValuesQuarter)
                setKeywords(keywords)
                setSecType([''])
                setSecId([''])
                setSecTypeSub([custom_filters['sec_type_sub']])
                setSectorSub([custom_filters['sector_sub']])
                setToShowDuration(true)
                setToShowCap(true)
                setColumn2(response_filters.data.column2)
                setColTab2(response_filters.data.column2)
                setDisplay(false)
                setLoading(false)
            } else {
                setLoading(false)
            }
            if ('is_data' in response_filters.data) {
                if (response_filters.data.is_data === null) {
                    setLoading(false)
                    setDisplay(false)
                    // setCustomFilters(custom_filters_start)
                    Swal.fire({
                        title: 'מידע',
                        text: 'אין היסטוריה עבור החיפוש המבוקש',
                        icon: 'info',
                        // confirmButtonText: 'Close'
                        confirmButtonText: 'סגור'
                    })
                }
            }
            console.log(response_filters.data)
        }

        function setNewFavoriteSearches(existing_storage) {

            if (Object.keys(existing_storage)?.length === 0) {
                // console.log('Favourite Searches are empty');
            } else {
                const keywordsList = [];
                for (const key in existing_storage) {
                    if (existing_storage.hasOwnProperty(key) && existing_storage[key].keywords) {
                        const joinedKeywords = existing_storage[key].keywords.join(' | ');
                        // keywordsList.push(joinedKeywords);
                        keywordsList.push({[existing_storage[key].chartType]: joinedKeywords});
                    }
                }
                // console.log(keywordsList)
                setFavSearches(keywordsList)
            }
        }

        const deleteItem = (index) => {
            const newSearches = lastSearches.filter((search, i) => i !== index);
            setLastSearches(newSearches);
        };

        const deleteFavItem = (index) => {
            const newSearches = favSearches.filter((search, i) => i !== index);
            setFavSearches(newSearches);
        };


        const search = document.getElementById("search");

        const fetchData = async (keyword) => {
            try {
                const data = {
                    'keyword': keyword
                }
                const response = await axios.post(`${BASE_URL}/api/search/`, data);

                // SETTING SUGGESTIONS BASED ON SEARCH TERMS
                if (searchedTerms?.length === 0) {
                    setSearchSuggestions(response.data || []);
                }

                if (searchedTerms?.length >= 1) {
                    // Check if any term has '0' or '15' at index 3 [a group]
                    const hasZeroOrFifteen = searchedTerms.some(term => term[3] === '0' || term[3] === '15');
                
                    // IF SEARCH TERM IS A GROUP
                    if (hasZeroOrFifteen) {
                        setSearchSuggestions([]);

                    // IF NOT
                    } else {
                        setSearchSuggestions(response.data.filter(item => item[3] !== '0' && item[3] !== '15'));
                    }
                }
                

            } catch (error) {
                console.log(error);
            }
        };
        
        

        const isLogin = async () => {
            console.log("checking user login")
            try {
                const token = sessionStorage.getItem('accessToken');

                const response = await axios.get(`${BASE_URL}/accounts/protected/`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 401) {
                    console.log("Not Login")
                    handle_logout()
                } else {
                    // Handle other errors
                    console.log('An error occurred:', error);
                    console.log('isLogged in - FALSE')
                }
            }
        }

        const fetchRightChartData = async (
            sec_id,
            sec_type_sub,
            col_name,
            checkedValuesMonths_ = checkedValuesMonths,
            checkedValuesQuarter_ = checkedValuesQuarter,
            keywords_ = keywords,
            sec_type = secType,
            column_ = column,
            sector_sub_ = sectorSub,
            one_or_two = oneOrTwo,
            col_tab = column2,
            filters_ = filters,
            colTab2_ = colTab2
        ) => {
            setDisplay(false)
            setLoading(true)
            await isLogin()
            setToShowDuration(false)
            setToShowCap(false)
            setIsCustomFilter(false)
            setSeries([])
            setLabelsData([])
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setGrandSum(0)
            setLeftChartMsg(false)
            setSecIdDataList([])
            // setRelatedCorpsIds([])
            let months = []
            let quarters = []
            
            checkedValuesMonths_?.forEach(function (value, index) {
                if (value) {
                    months.push(dates["quarterly"]['months'][index][0])

                }
            });
            checkedValuesQuarter_?.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates["quarterly"]['quarters'][index][0])

                }
            });
            if (quarters?.length === 0 && months?.length === 0) {
                quarters.push(dates["quarterly"]['quarters'][0][0])
                months.push(dates["quarterly"]['months'][0][0])
            } else if (quarters?.length === 1 && months?.length === 0) {
                months.push(dates["quarterly"]['months'][0][0])
            }

            const data = {
                "sec_type_sub": sec_type_sub,
                "sec_id": sec_id,
                "selected_data_column": col_name,
                "dates_m": months,
                "dates_q": quarters,
                "keywords": keywords_,
                'sec_type': sec_type,
                'column': column_,
                "sector_sub": sector_sub_,
                "one_or_two": one_or_two,
                "filters": filters_,
                "column2": col_tab,
                "col_tab_2": colTab2_, 

                "chartType": "quarterly",
                "email": sessionStorage.getItem('email')
            }
            
            const current = {
                "sec_id": sec_id,
                "sec_type_sub": sec_type_sub,
                "col_name": col_name,
                "checkedValuesMonths_": checkedValuesMonths_,
                "checkedValuesQuarter_": checkedValuesQuarter_,
                "keywords_": keywords_,
                "sec_type": sec_type,
                "column_": column_,
                "sector_sub_": sector_sub_,
                "one_or_two": one_or_two,
                "col_tab": col_tab,
                "filters_": filters_,
                "colTab2_": colTab2_,
                "chartType": "quarterly"
            }
            see_star(current)


            try {
                axios.post(`${BASE_URL}/api/search_step_2/`, data)
                    .then((response) => {
                        console.log("SEARCH_STEP_2 RESPONSE - " , response, data);
                        console.log("response", response.data)
                        setChartType('quarterly');
                        if (response.data?.length === 0) {
                            // alert("אין היסטוריה עבור החיפוש המבוקש")
                            setLoading(false)
                            // setDisplay(true)


                        } else {
                            if (response.data.series?.length !== 0) {
                                setSeries(response.data.series)
                                let count = quarters?.length
                                if (quarters?.length > 1) {
                                    count = quarters?.length + 1
                                } else if (months?.length > 1) {
                                    count = months?.length + 1
                                } else if (sec_id?.length > 1) {
                                    count = sec_id?.length + 1
                                } else {
                                    count = sec_id?.length
                                }
                                

                                // THIS IS TO OFFSET FOR THE LEGENDS & X-AXIS LABELS
                                let calculatedHeight = response.data.series_sum?.length * 31 + 110;
                                if (column2 !== "sectors" || sec_type[0] !== "0" || sec_type[0] !== "15") {
                                    // console.log('GOT HERE OOOOOOOOOOOOOOOOOOOO - --- --  - ', column2, secType, sec_type)
                                    calculatedHeight = response.data.series_sum?.length * 31 + 110 + 31
                                }
                                if (count > 1) {
                                    console.log('THE COUNT - ', count)
                                    calculatedHeight = (response.data.series_sum?.length * count * 20) + (response.data.series_sum?.length * 11) + 100 + ((count - 1) * 10);
                                }
                                setLabelsDataNum(calculatedHeight);
                            }
                            // console.log(response.data)
                            setLabelsData(response.data.series_sum)
                            setAllLabelsNames(response.data.all_labels_names)
                            setAllQuarterNames(response.data.list_quarter_names)
                            setGrandSum(response.data.all_values_sum)
                            setLoading(false)
                            setLagends(response.data.legands)
                            if (quarters?.length === 0){
                                setNumQuarters(months?.length)
                            }else{
                                setNumQuarters(quarters?.length)
                            }

                            setRelatedCorpsIds(response.data.related_corps_ids)
                            setSearchSuggestions([]);
                            setRightChartCatagories(response.data.series_sum.map((row, ind) => (response.data.all_labels_names[row[0]])))
                            setColumn(column_)
                            setSelectedDataColumn(col_name)
                            setCheckedValuesMonths(checkedValuesMonths_)
                            setCheckedValuesQuarter(checkedValuesQuarter_)
                            setKeywords(keywords_)
                            setSecType(sec_type)
                            setSecId(sec_id)
                            setSecTypeSub(sec_type_sub)
                            setSectorSub(sector_sub_)
                            // Close popup only when no. of sec_id = 0
                            // THIS KEEPS THE UI BETTER
                            if (sec_id?.length === 0) {
                                setLastField(false)
                            }
                            if (response.data.sec_ids_data) {
                                setSecIdDataList(response.data.sec_ids_data)
                            }
                            if (response.data.selected_row_filters) {
                                setFilters(response.data.selected_row_filters)
                                // console.log("_________________________________________________")
                                // console.log(response.data.selected_row_filters.filters_valid)
                                // console.log("_________________________________________________")
                                if (response.data.selected_row_filters.filters_valid === 'false') {
                                    // Swal.fire({
                                    //     title: 'מידע',
                                    //     text: 'אין היסטוריה עבור החיפוש המבוקש',
                                    //     icon: 'info',
                                    //     confirmButtonText: 'OK'
                                    // })
                                    // Remove this modal
                                }
                            } else {
                                setFilters(filters_start)
                            }
                            setRelatedSecurities(response.data.related_securities)
                            set_related_securities_legend(response.data.related_securities_legend)

                            setToShowDuration(true)
                            setToShowCap(true)
                            setColumn2(col_tab)
                            setColTab2(colTab2_)
                            setDisplay(true)
                            search.placeholder = "הוסף ני”ע להשוואה";
                        }

                    }, (error) => {
                        console.log('the search 2 error - ', error);
                        setToShowCap(false)
                        setToShowDuration(false)
                        setFilters(filters_start)
                        setLoading(false)
                        // Swal.fire({
                        //     title: 'אירעה תקלה',
                        //     text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                        //     icon: 'error',
                        //     // confirmButtonText: 'Close'
                        //     confirmButtonText: 'סגור'
                        // })
                        Swal.fire({
                            title: 'אירעה תקלה',
                            text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                            icon: "error",
                            showCancelButton: true,
                            confirmButtonColor: "#66b8d7",
                            cancelButtonColor: "#7066e0",
                            confirmButtonText: "שלח",
                            cancelButtonText: "בטל"
                          }).then(async (result) => {
                            const reportData = {
                                title: error?.response?.status,
                                email: sessionStorage.getItem('email'),
                                content: error?.message,
                            }
                            const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                            if (response.status === 200 || response.status === 201) {
                                if (result.isConfirmed) {
                                  Swal.fire({
                                    title: "הודעתך התקבלה",
                                    text: "צוות ברוקרס קונקט יטפל בתקלה. תודה על שיתוף הפעולה!",
                                    icon: "success"
                                  });
                                }
                            } else {
                                console.log("Unexpected server response:", response.data);
                                // Handle any other server responses here, you can add more conditions to check for other status codes
                            }
                        });
                        // alert("Ohh: This query is new for me, please discuss it with your developer")
                        setLoading(false)
                    });
            } catch (error) {
                console.log(error);

                // alert("Ohh: Something went wrong, please discuss it with your developer")
                // Swal.fire({
                //     title: 'אירעה תקלה',
                //     text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל  ',
                //     icon: 'error',
                //     // confirmButtonText: 'Close'
                //     confirmButtonText: 'סגור'
                // }) 
                Swal.fire({
                    title: 'אירעה תקלה',
                    text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#66b8d7",
                    cancelButtonColor: "#7066e0",
                    confirmButtonText: "Report"
                  }).then(async (result) => {
                    const reportData = {
                        title: error?.response?.status,
                        email: sessionStorage.getItem('email'),
                        content: error?.message,
                    }
                    const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                    if (response.status === 200 || response.status === 201) {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: "Sent!",
                            text: "Our Team would look into it",
                            icon: "success"
                          });
                        }
                    } else {
                        console.log("Unexpected server response:", response.data);
                        // Handle any other server responses here, you can add more conditions to check for other status codes
                    }
                });
                clear_all()
            }
        };
        

        const fetchRightChartDataForContinuous = async (
            sec_id,
            sec_type_sub,
            col_name,
            keywords_ = keywords,
            sec_type = secType,
            column_ = column,
            sector_sub_ = sectorSub,
            one_or_two = oneOrTwo,
            col_tab = column2,
            filters_ = filters,
            colTab2_ = colTab2
        ) => {
            setDisplay(false)
            setLoading(true)
            await isLogin()
            setToShowDuration(false)
            setToShowCap(false)
            setIsCustomFilter(false)
            setSeries([])
            setLabelsData([])
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setGrandSum(0)
            setLeftChartMsg(false)
            setSecIdDataList([])
            // setRelatedCorpsIds([])
            // let months = []
            // let quarters = []
            // checkedValuesMonths_.forEach(function (value, index) {
            //     if (value) {
            //         months.push(dates['months'][index][0])

            //     }
            // });
            // checkedValuesQuarter_.forEach(function (value, index) {
            //     if (value) {
            //         quarters.push(dates['quarters'][index][0])

            //     }
            // });

            const data = {
                "sec_type_sub": sec_type_sub,
                "sec_id": sec_id,
                "selected_data_column": col_name,
                "keywords": keywords_,
                'sec_type': sec_type,
                'column': column_,
                "sector_sub": sector_sub_,
                "one_or_two": one_or_two,
                "filters": filters_,
                "column2": col_tab,
                "col_tab_2": colTab2_,
                
                "chartType": "continuous",
                "email": sessionStorage.getItem('email')
            }

            const current = {
                "sec_id": sec_id,
                "sec_type_sub": sec_type_sub,
                "col_name": col_name,
                "keywords_": keywords_,
                "sec_type": sec_type,
                "column_": column_,
                "sector_sub_": sector_sub_,
                "one_or_two": one_or_two,
                "col_tab": col_tab,
                "filters_": filters_,
                "colTab2_": colTab2_,
                "chartType": "continuous"
            }
            see_star(current)

            try {
                // console.log('also got here ----- ', data)
                axios.post(`${BASE_URL}/api/search_step_2_continuous/`, data)
                    .then((response) => {
                        console.log('Got here "SEARCH_STEP_2_CONTINUOUS" - ', response, data);
                        setChartType('continuous');
                        if (response.data?.length === 0) {
                            // alert("אין היסטוריה עבור החיפוש המבוקש")
                            setLoading(false)
                            // setDisplay(true)


                        } else {
                            if (response.data.series?.length !== 0) {
                                setSeries(response.data.series)
                                
                                // THIS IS TO OFFSET FOR THE LEGENDS & X-AXIS LABELS
                                let calculatedHeight = response.data.series_sum?.length * 31 + 110;

                                setLabelsDataNum(calculatedHeight);
                            }
                            
                            // 4 MAJOR DATA
                            setLabelsData(response.data.series_sum)
                            setAllLabelsNames(response.data.all_labels_names)
                            setAllQuarterNames(response.data.list_quarter_names)
                            setGrandSum(response.data.all_values_sum)

                            setLoading(false)
                            setLagends(response.data.legands)
                            setNumQuarters(1)

                            setRelatedCorpsIds(response.data.related_corps_ids)
                            // setSearchSuggestions([]);
                            setRightChartCatagories(response.data.series_sum.map((row, ind) => (response.data.all_labels_names[row[0]])))
                            // setColumn(column_)
                            setSelectedDataColumn(col_name)
                            // setCheckedValuesMonths(checkedValuesMonths_)
                            // setCheckedValuesQuarter(checkedValuesQuarter_)
                            setKeywords(keywords_)
                            setSecType(sec_type)
                            setSecId(sec_id)
                            setSecTypeSub(sec_type_sub)
                            setSectorSub(sector_sub_)
                            setLastField(false)
                            if (response.data.sec_ids_data) {
                                setSecIdDataList(response.data.sec_ids_data)
                            }
                            // if (response.data.selected_row_filters) {
                            //     setFilters(response.data.selected_row_filters)
                            //     // console.log("_________________________________________________")
                            //     // console.log(response.data.selected_row_filters.filters_valid)
                            //     // console.log("_________________________________________________")
                            //     if (response.data.selected_row_filters.filters_valid === 'false') {
                            //         // Swal.fire({
                            //         //     title: 'מידע',
                            //         //     text: 'אין היסטוריה עבור החיפוש המבוקש',
                            //         //     icon: 'info',
                            //         //     confirmButtonText: 'OK'
                            //         // })
                            //         // Remove this modal
                            //     }
                            // } else {
                            //     setFilters(filters_start)
                            // }
                            setRelatedSecurities(response.data.related_securities)
                            set_related_securities_legend(response.data.related_securities_legend)

                            setToShowDuration(true)
                            setToShowCap(true)
                            setColumn2(col_tab)
                            setColTab2(colTab2_)
                            setDisplay(true)
                            search.placeholder = "הוסף ני”ע להשוואה";
                        }

                    }, (error) => {
                        console.log('the search 2 continuous error - ', error);
                        setToShowCap(false)
                        setToShowDuration(false)
                        setFilters(filters_start)
                        setLoading(false)
                        // Swal.fire({
                        //     title: 'אירעה תקלה',
                        //     text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                        //     icon: 'error',
                        //     // confirmButtonText: 'Close'
                        //     confirmButtonText: 'סגור'
                        // })
                        Swal.fire({
                            title: 'אירעה תקלה',
                            text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                            icon: "error",
                            showCancelButton: true,
                            confirmButtonColor: "#66b8d7",
                            cancelButtonColor: "#7066e0",
                            confirmButtonText: "שלח",
                            cancelButtonText: "בטל"
                          }).then(async (result) => {
                            const reportData = {
                                title: error.response.status,
                                email: sessionStorage.getItem('email'),
                                content: error.message,
                            }
                            const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                            if (response.status === 200 || response.status === 201) {
                                if (result.isConfirmed) {
                                  Swal.fire({
                                    title: "הודעתך התקבלה",
                                    text: "צוות ברוקרס קונקט יטפל בתקלה. תודה על שיתוף הפעולה!",
                                    icon: "success"
                                  });
                                }
                            } else {
                                console.log("Unexpected server response:", response.data);
                                // Handle any other server responses here, you can add more conditions to check for other status codes
                            }
                        });
                        // alert("Ohh: This query is new for me, please discuss it with your developer")
                        setLoading(false)
                    });
            } catch (error) {
                console.log(error);

                // alert("Ohh: Something went wrong, please discuss it with your developer")
                // Swal.fire({
                //     title: 'אירעה תקלה',
                //     text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל  ',
                //     icon: 'error',
                //     // confirmButtonText: 'Close'
                //     confirmButtonText: 'סגור'
                // }) 
                Swal.fire({
                    title: 'אירעה תקלה',
                    text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#66b8d7",
                    cancelButtonColor: "#7066e0",
                    confirmButtonText: "Report"
                  }).then(async (result) => {
                    const reportData = {
                        title: error.response.status,
                        email: sessionStorage.getItem('email'),
                        content: error.message,
                    }
                    const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                    if (response.status === 200 || response.status === 201) {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: "Sent!",
                            text: "Our Team would look into it",
                            icon: "success"
                          });
                        }
                    } else {
                        console.log("Unexpected server response:", response.data);
                        // Handle any other server responses here, you can add more conditions to check for other status codes
                    }
                });
                 clear_all()


            }
        };
        

        const fetchRightChartDataForFHolder = async (
            sec_id,
            sec_type_sub,
            col_name,
            checkedValuesQuarter_ = checkedValuesQuarter,
            keywords_ = keywords,
            sec_type = secType,
            column_ = column,
            sector_sub_ = sectorSub,
            one_or_two = oneOrTwo,
            col_tab = column2,
            filters_ = filters,
            colTab2_ = colTab2
        ) => {
            setDisplay(false)
            setLoading(true)
            await isLogin()
            setToShowDuration(false)
            setToShowCap(false)
            setIsCustomFilter(false)
            setSeries([])
            setLabelsData([])
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setGrandSum(0)
            setLeftChartMsg(false)
            setSecIdDataList([])
            setRelatedCorpsIds([])
            setRelatedSecurities([])
            
            // GET LATEST 12 QUARTERS FOR F_HOLDERS
            // const response = await axios.get(`${BASE_URL}/api/get_dates_f_holder`);
            // const dates = {'months': response.data.months, 'quarters': response.data.quarters};
            // setDates(dates);
            // console.log('GET DATES, F_HOLDER - ', response.data)

            let quarters = []
            checkedValuesQuarter_.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates["f_holder"]['quarters'][index][0])
                }
            });
            if (quarters?.length === 0) {
                quarters.push(dates["f_holder"]['quarters'][0][0])
            }

            const data = {
                "sec_type_sub": sec_type_sub,
                "sec_id": sec_id,
                "selected_data_column": col_name,
                "dates_q": quarters,
                "keywords": keywords_,
                'sec_type': sec_type,
                'column': column_,
                "sector_sub": sector_sub_,
                "one_or_two": one_or_two,
                "filters": filters_,
                "column2": col_tab,
                "col_tab_2": colTab2_,
                
                "chartType": "f_holder",
                "email": sessionStorage.getItem('email')
            }
            
            const current = {
                "sec_id": sec_id,
                "sec_type_sub": sec_type_sub,
                "col_name": col_name,
                "keywords_": keywords_,
                "sec_type": sec_type,
                "column_": column_,
                "sector_sub_": sector_sub_,
                "one_or_two": one_or_two,
                "col_tab": col_tab,
                "filters_": filters_,
                "colTab2_": colTab2_,
                "chartType": "f_holder"
            }
            see_star(current)

            try {
                // console.log('also got here ----- ', data)
                axios.post(`${BASE_URL}/api/search_step_2_f_holder/`, data)
                    .then((response) => {
                        console.log('Got here "SEARCH_STEP_2_F_HOLDER" - ', response, data);
                        setChartType('f_holder');
                        if (response.data?.length === 0) {
                            // alert("אין היסטוריה עבור החיפוש המבוקש")
                            setLoading(false)
                            // setDisplay(true)


                        } else {
                            if (response.data.series?.length !== 0) {
                                setSeries(response.data.series)
                                let count = quarters?.length
                                if (quarters?.length > 1) {
                                    count = quarters?.length + 1
                                }else {
                                    count = sec_id?.length
                                }

                                // SINGLE CHART
                                // THIS IS TO OFFSET FOR THE LEGENDS & X-AXIS LABELS
                                let calculatedHeight = response.data.series_sum?.length * 31 + 110;

                                if (count > 1) {
                                    calculatedHeight = (response.data.series_sum?.length * count * 20) + (response.data.series_sum?.length * 11) + 100 + ((count - 1) * 10);
                                }
                                setLabelsDataNum(calculatedHeight);
                            }
                            
                            // 4 MAJOR DATA
                            setLabelsData(response.data.series_sum)
                            setAllLabelsNames(response.data.all_labels_names)
                            setAllQuarterNames(response.data.list_quarter_names)
                            setGrandSum(response.data.all_values_sum)

                            setLoading(false)
                            setLagends(response.data.legands)
                            setNumQuarters(quarters?.length)
                            // setSearchSuggestions([]);
                            setRightChartCatagories(response.data.series_sum.map((row, ind) => (response.data.all_labels_names[row[0]])))
                            // setColumn(column_)
                            setSelectedDataColumn(col_name)
                            // setCheckedValuesMonths(checkedValuesMonths_)
                            // setCheckedValuesQuarter(checkedValuesQuarter_)
                            setKeywords(keywords_)
                            setSecType(sec_type)
                            setSecId(sec_id)
                            setSecTypeSub(sec_type_sub)
                            setSectorSub(sector_sub_)
                            setLastField(false)
                            if (response.data.sec_ids_data) {
                                setSecIdDataList(response.data.sec_ids_data)
                            }
                            
                            set_related_securities_legend(response.data.related_securities_legend)

                            setToShowDuration(true)
                            setToShowCap(true)
                            setColumn2(col_tab)
                            setColTab2(colTab2_)
                            setDisplay(true)
                            search.placeholder = "הוסף ני”ע להשוואה";
                        }

                    }, (error) => {
                        console.log('the search 2 f_holder error - ', error);
                        setToShowCap(false)
                        setToShowDuration(false)
                        setFilters(filters_start)
                        setLoading(false)
                        
                        Swal.fire({
                            title: 'אירעה תקלה',
                            text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                            icon: "error",
                            showCancelButton: true,
                            confirmButtonColor: "#66b8d7",
                            cancelButtonColor: "#7066e0",
                            confirmButtonText: "שלח",
                            cancelButtonText: "בטל"
                          }).then(async (result) => {
                            const reportData = {
                                title: error.response.status,
                                email: sessionStorage.getItem('email'),
                                content: error.message,
                            }
                            const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                            if (response.status === 200 || response.status === 201) {
                                if (result.isConfirmed) {
                                  Swal.fire({
                                    title: "הודעתך התקבלה",
                                    text: "צוות ברוקרס קונקט יטפל בתקלה. תודה על שיתוף הפעולה!",
                                    icon: "success"
                                  });
                                }
                            } else {
                                console.log("Unexpected server response:", response.data);
                                // Handle any other server responses here, you can add more conditions to check for other status codes
                            }
                        });
                        // alert("Ohh: This query is new for me, please discuss it with your developer")
                        setLoading(false)
                    });
            } catch (error) {
                console.log(error);

                // alert("Ohh: Something went wrong, please discuss it with your developer")
                // Swal.fire({
                //     title: 'אירעה תקלה',
                //     text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל  ',
                //     icon: 'error',
                //     // confirmButtonText: 'Close'
                //     confirmButtonText: 'סגור'
                // }) 
                Swal.fire({
                    title: 'אירעה תקלה',
                    text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#66b8d7",
                    cancelButtonColor: "#7066e0",
                    confirmButtonText: "Report"
                  }).then(async (result) => {
                    const reportData = {
                        title: error.response.status,
                        email: sessionStorage.getItem('email'),
                        content: error.message,
                    }
                    const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                    if (response.status === 200 || response.status === 201) {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: "Sent!",
                            text: "Our Team would look into it",
                            icon: "success"
                          });
                        }
                    } else {
                        console.log("Unexpected server response:", response.data);
                        // Handle any other server responses here, you can add more conditions to check for other status codes
                    }
                });
                 clear_all()


            }
        };
        

        const searchInput = (e) => {
            // IF USER ADD MULTIPLE QUARTERS, DISABLE OPTION TO ADD MORE SEARCHES
            if (valuesForMonthsAndQuarters[1] >= 2) {
                e.target.value = '';
                return;
            }

            // IF CHART TYPE IS 'continuous updates', DISABLE MULTIPLE SEARCH
            if (chartType === "continuous" && searchedTerms?.length !== 0 && seriesData?.length !== 0) {
                e.target.value = '';
                return;
            }

            if (e.target.value?.length > 0) {
                // RESETS VALUE AFTER ADVANCED SEARCH (maybe put in quarterly,... search click)
                if (isCustomFilter) {
                    clear_all()
                }

                fetchData(e.target.value)
                setVisibleSearchPopup(false);
                setLastField(false);
                setVisibleSuggestions(true);
                setIsCustomFilter(false)
            } else {
                setIsCustomFilter(true)
                setVisibleSearchPopup(true);
                setVisibleSuggestions(false);
                if (isCustomFilter) {
                    clear_all()
                }

            }
        };

        const handleRowClick = async (rowData, index, type="regular") => {
            function isGroupSec() {
                if (column2 == "sectors" || secType[0] === "0" || secType[0] === "15") {
                    return true
                }
                return false
            }

            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setLoadingLeft(true)

            // // console.log(secId, secTypeSub)
            if (num_quarters > 1) {
                index = 0
                setSelectedRowId(rowData[0].toString())
            } else {
                setSelectedRowId(rowData[0].toString() + allQuarterNames[index])
            }
            console.log('THE INFORMATION = ', secTypeSub, index)
            const data = {
                "sec_type_sub": secTypeSub[index],
                "sec_id": isGroupSec() ? rowData[0] : secId[index],
                "control_corp": rowData[0],
                "selected_data_column": selectedDataColumn,
                "manager_id": chartType === "f_holder" ? rowData[0] : "",
                "type": rowData[0] === "sum_all" || isGroupSec() ? "sum" : "regular"
            }

            // setSelectedRowId(rowData[0].toString()+allQuarterNames[index])
            axios.post(`${BASE_URL}/api/left_charts/`, data)
                .then((response) => {

                    console.log('LEFT CHARTS RESPONSE ', response)

                    setbarMonthlyData(response.data.data_month)
                    setbarQuarterlyData(response.data.data_quarter)
                    setbarMonthlyCategories(response.data.categories_month)
                    setbarQuarterlyCategories(response.data.categories_quarter)
                    setLoadingLeft(false)
                    setLeftChartMsg(true)
                }, (error) => {
                    // clear_all()
                    Swal.fire({
                        title: 'אירעה תקלה',
                        text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                        icon: "error",
                        showCancelButton: true,
                        confirmButtonColor: "#66b8d7",
                        cancelButtonColor: "#7066e0",
                        confirmButtonText: "שלח",
                        cancelButtonText: "בטל"
                        }).then(async (result) => {
                        const reportData = {
                            title: error?.response?.status,
                            email: sessionStorage.getItem('email'),
                            content: error?.message,
                        }
                        const response = await axios.post(`${BASE_URL}/support/report/`, reportData)
                        if (response.status === 200 || response.status === 201) {
                            if (result.isConfirmed) {
                                Swal.fire({
                                title: "הודעתך התקבלה",
                                text: "צוות ברוקרס קונקט יטפל בתקלה. תודה על שיתוף הפעולה!",
                                icon: "success"
                                });
                            }
                        } else {
                            console.log("Unexpected server response:", response.data);
                            // Handle any other server responses here, you can add more conditions to check for other status codes
                        }
                    });
                    
                    setbarMonthlyCategories([])
                    setbarMonthlyData([])
                    setbarQuarterlyData([])
                    setbarQuarterlyCategories([])
                    setLoadingLeft(false)
                    // console.log(error);
                });

        };

        const handleCustomDropdownTop = (opt) => {
            let col_name = "sum_market_cap"
            if (opt === "שווי שוק") {
                col_name = 'sum_market_cap'
            } else if (opt === "אחוז מהון רשום") {
                col_name = 'sum_par_percentage'
            } else {
                col_name = "sum_par_value"
            }


            if (secTypeSub?.length !== 0) {
                if (chartType === 'quarterly') {
                    fetchRightChartData(secId, secTypeSub, col_name).then(r => {
                    })
                } else if (chartType === 'continuous') {
                    fetchRightChartDataForContinuous(secId, secTypeSub, col_name).then(r => {
                    })
                } else if (chartType === 'f_holder') {
                    fetchRightChartDataForFHolder(secId, secTypeSub, col_name, checkedValuesQuarter).then(r => {
                    })
                }
            }
            setSelectedDataColumn(col_name)

        };

        const handleSelectionChange = (col, one_two) => {
            // console.log(col)
            // console.log("here i am")
            // setColumn(col)
            if (secTypeSub?.length !== 0) {
                fetchRightChartData(
                    secId,
                    secTypeSub,
                    selectedDataColumn,
                    checkedValuesMonths,
                    checkedValuesQuarter,
                    keywords,
                    secType,
                    col,
                    sectorSub,
                    one_two).then(r => {
                })
            }
        }

        const handleSelectionChange2 = (col) => {
            // console.log(col)
            setDescription(modal_description[col][0])
            setDescription_(modal_description[col][2])
            setTitleModal(modal_description[col][1])
            if (secTypeSub?.length !== 0) {
                setColTab2(col)
                setColumn2(col)
                if (col !== "holders") {
                    toggleSearch2("block")
                } else {
                    toggleSearch2("hidden")

                    fetchRightChartData(
                        secId,
                        secTypeSub,
                        selectedDataColumn,
                        checkedValuesMonths,
                        checkedValuesQuarter,
                        keywords,
                        secType,
                        column,
                        sectorSub,
                        oneOrTwo,
                        col,
                        filters_start, // CHANGED
                        col
                    ).then(r => {
                        // setColumn2(col)
                        // setColTab2(col)
                    })
                }
            }
        }

        function generateUniqueIdentifier(jsonObject) {
            const jsonString = JSON.stringify(jsonObject);
            return CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);
        }

        const see_star = (data) => {
            let stateObject = {
                'secId': data['sec_id'],
                'secTypeSub': data['sec_type_sub'],
                'selectedDataColumn': data['col_name'],
                'checkedValuesMonths': data['checkedValuesMonths_'],
                'checkedValuesQuarter': data['checkedValuesQuarter_'],
                'keywords': data['keywords_'],
                'secType': data['sec_type'],
                'column': data['column_'],
                'sectorSub': data['sector_sub_'],
                'oneOrTwo': data['one_or_two'],
                'column2': data['col_tab'],
                'filters': data['filters_'],
                'colTab2': data['colTab2_'],
                'chartType': data['chartType']
            }

            const key = generateUniqueIdentifier(stateObject)
            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            if (Object.keys(existing_storage)?.length === 0) {
                setStarActive(false)
            } else {
                if (existing_storage.hasOwnProperty(key)) {
                    setStarActive(true)
                } else {
                    setStarActive(false)
                }
            }
            // console.log(key)

        }

        const getFromLocalStorage = (increment) => {
            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            if (Object.keys(existing_storage)?.length === 0) {
                // console.log('Favourite Searches are empty');
            } else {
                const objects_list = [];
                for (const key in existing_storage) {
                    if (existing_storage.hasOwnProperty(key) && existing_storage[key].keywords) {
                        const joinedKeywords = existing_storage[key]
                        objects_list.push(joinedKeywords);
                    }
                }
                // console.log(objects_list)
                let index = 0;
                if (objects_list?.length !== 0) {
                    if (currentFavouriteKey === -1 && increment === -1) {
                        index = objects_list?.length - 1
                    } else if (currentFavouriteKey === 0 && increment === -1) {
                        index = objects_list?.length - 1
                    } else if (currentFavouriteKey === objects_list?.length - 1 && increment === 1) {
                        index = 0
                    } else {
                        index = currentFavouriteKey + increment
                    }
                    let my_object = {}
                    my_object = objects_list[index]
                    fetchRightChartData(
                        my_object.secId,
                        my_object.secTypeSub,
                        my_object.selectedDataColumn,
                        my_object.checkedValuesMonths,
                        my_object.checkedValuesQuarter,
                        my_object.keywords,
                        my_object.secType,
                        my_object.column,
                        my_object.sectorSub,
                        my_object.oneOrTwo,
                        my_object.column2,
                        my_object.filters,
                        my_object.colTab2
                    ).then(r => {
                        setCurrentFavouriteKey(index)
                        setStarActive(true)
                        var result = my_object.keywords.map(function (word) {
                            return [word];
                        });
                        setSearchedTerms(result)
                    })
                } else {
                    setStarActive(false)
                    // console.log("No Favourite is found")
                }

            }
        }

        const activate_star_function = (active) => {
            if (active) {
                let stateObject = {
                    'secId': secId,
                    'secTypeSub': secTypeSub,
                    'selectedDataColumn': selectedDataColumn,
                    'checkedValuesMonths': checkedValuesMonths,
                    'checkedValuesQuarter': checkedValuesQuarter,
                    'keywords': keywords,
                    'secType': secType,
                    'column': column,
                    'sectorSub': sectorSub,
                    'oneOrTwo': oneOrTwo,
                    'column2': column2,
                    'filters': filters,
                    'colTab2': colTab2,
                    'chartType': chartType
                }

                const key = generateUniqueIdentifier(stateObject)
                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                // console.log("Star is active ")
                const stateObjectKey = {}
                stateObjectKey[key] = stateObject
                existing_storage[key] = stateObject
                setStarActive(true)
                localStorage.setItem('stateObject', JSON.stringify(existing_storage));
                setNewFavoriteSearches(existing_storage)

                setCurrentFavouriteKey(currentFavouriteKey + 1)
                saveToFavSearch();
                
            } else {
                // console.log("0")
                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                // console.log("deleting the favourite ")
                // delete existing_storage[key]
                const remaining_storage = removeObjectKeyByIndex(existing_storage, currentFavouriteKey)
                // console.log(remaining_storage)
                setStarActive(false)
                localStorage.setItem('stateObject', JSON.stringify(remaining_storage));
                const newSearches = favSearches.filter((search, i) => i !== currentFavouriteKey);
                setFavSearches(newSearches);
                setCurrentFavouriteKey(currentFavouriteKey - 1);
                removeFromFavSearch();
            }

        }

        function formatSecIdAndChartType(secId, chartType) {
            const secIdFormatted = secId.join(", ");
            return `${secIdFormatted} | ${chartType}`;
        }
        
        const saveToFavSearch = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/support/fav_list/`, {
                        email: sessionStorage.getItem('email'),
                        fav_item: formatSecIdAndChartType(secId, chartType),
                });
            } catch (error) {
                console.error('Error saving fav:', error);
            }
        }

        const removeFromFavSearch = async () => {
            try {
                const response = await axios.delete(`${BASE_URL}/support/fav_list/`, {
                    data: {
                        email: sessionStorage.getItem('email'),
                        fav_item: formatSecIdAndChartType(secId, chartType),
                    }
                });
            } catch (error) {
                console.error('Error saving fav:', error);
            }
        }


        function getObjectByIndex(obj, index) {
            const keys = Object.keys(obj);
            const values = Object.values(obj);

            if (index >= 0 && index < keys?.length) {
                const key = keys[index];
                const value = values[index];
                const result = {};
                result[key] = value;
                return result;
            }

            return null; // Return null if the index is out of bounds
        }

        function removeObjectKeyByIndex(obj, indexToRemove) {
            const keys = Object.keys(obj);
            if (indexToRemove >= 0 && indexToRemove < keys?.length) {
                const keyToRemove = keys[indexToRemove];
                const updatedObject = {...obj};
                delete updatedObject[keyToRemove];
                return updatedObject;
            }
            return obj;
        }

        const handleCustomDropdown = (event) => {
            setCustomDropdown(!customDropdown);
        };

        const sec_id_search = (sec_name) => {
            // console.log(secIdDataList[sec_id])

            const value = secIdDataList[sec_name];
            // console.log('\n\n VOICEMAIL - ', sec_name, '\n\n', secIdDataList, '\n\n');

            async function getNewSearch() {
                try {
                    const response = await axios.post(`${BASE_URL}/api/get_data_by_sec_name/`, {
                        'sec_name': sec_name
                    });

                    const result = response?.data[0];
                    if (result) {
                        setSecId([result[1]])
                        setKeywords([result[0]])
                        setSecTypeSub([result[2]])
                        setSecType([result[3]])
                        setSector([result[4]])
                        setSectorSub([result[5]])
                        setCheckedValuesQuarter(checkedValuesDefault)
                        setCheckedValuesMonths(checkedValuesDefault)
                        setSearchedTerms([result]);
    
                        fetchRightChartData(
                            [result[1]], 
                            [result[2]], 
                            selectedDataColumn, 
                            checkedValuesDefault, 
                            checkedValuesDefault, 
                            [result[0]], 
                            [result[3]], 
                            column,
                            [result[5]],
                            oneOrTwo,
                            "holders",
                            filters_start, // CHANGED
                            "holders").then(r => {
                        })    
                    }
                }  catch (error) {
                }
            }
            getNewSearch();
        }

        const handleRelatedCorpID = (index) => {
            const value = relatedCorpsIds[index];
            // IF RELATIVE CORP IS IN THE SEARCHED TERMS, DISABLE LINK IF IT IS QUARTERLY
            if (secId.includes(value[1]) && chartType === "quarterly") {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }
            setSecTypeSub([relatedCorpsIds[index][2]])
            setSecType([relatedCorpsIds[index][3]])
            setSecId([relatedCorpsIds[index][1]])
            setKeywords([relatedCorpsIds[index][0]])
            // // console.log(relatedCorpsIds[index][1])
            fetchRightChartData(
                [relatedCorpsIds[index][1]],
                [relatedCorpsIds[index][2]],
                selectedDataColumn,
                checkedValuesMonths,
                checkedValuesQuarter,
                [relatedCorpsIds[index][0]],
                [relatedCorpsIds[index][3]],
                column,
                sectorSub,
                oneOrTwo,
                "holders",
                filters_start,
                "holders"
            ).then(r => {
            })

            // insert value to searched terms
            const newSearchedTerms = [relatedCorpsIds[index]];
            setSearchedTerms(newSearchedTerms);
            // // console.log(lastSearches);
            // // console.log(value)
            if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
                const newSearches = [...lastSearches, value];
                setLastSearches(newSearches);
            }

            // push the value to last searches
            // const newSearches = [...lastSearches, relatedCorpsIds[index]];
            // setLastSearches(newSearches);
        }

        const handleRelatedCorpIDMultiSearch = (index) => {
            // This immediate code is to Resolve issue
            // Removed Right Click from first object in relatedCorpsId
            if (relatedCorpsIds === index || num_quarters > 1) {
                return
            };

            // // console.log("in a add value")
            const value = relatedCorpsIds[index]
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecTypeSub([...secTypeSub, relatedCorpsIds[index][2]])
            setSecType([...secType, relatedCorpsIds[index][3]])
            setSecId([...secId, relatedCorpsIds[index][1]])
            setKeywords([...keywords, relatedCorpsIds[index][0]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, relatedCorpsIds[index][1]],
                [...secTypeSub, relatedCorpsIds[index][2]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, relatedCorpsIds[index][0]],
                [...secType, relatedCorpsIds[index][3]],
                column,
                sectorSub,
                oneOrTwo,
                "holders",
                filters_start, // CHANGED
                "holders"
            ).then(r => {
            })
            const newSearchedTerms = [value, ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
        }

        const addValue = (index,) => {
            // if (secId?.length === 1 && secId[0] === "") {
            //     secId([]);
            //     setKeywords([]);
            //     setSecType([]);
            //     setSecTypeSub([]);
            //     setSector([]);
            //     setSectorSub([]);
            // }
            // console.log('search suggestions ', searchSuggestions, index)
            const value = searchSuggestions[index];
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecId([...secId, searchSuggestions[index][1]])
            setKeywords([...keywords, searchSuggestions[index][0]])
            setSecTypeSub([...secTypeSub, searchSuggestions[index][2]])
            setSecType([...secType, searchSuggestions[index][3]])
            setSector([...sector, searchSuggestions[index][4]])
            setSectorSub([...sectorSub, searchSuggestions[index][5]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            setExistsInMsFreq([...existsInMsFreq, searchSuggestions[index][6]])
            
            fetchRightChartData(
                [...secId, searchSuggestions[index][1]], 
                [...secTypeSub, searchSuggestions[index][2]], 
                selectedDataColumn, 
                checkedValuesDefault, 
                checkedValuesDefault, 
                [...keywords, searchSuggestions[index][0]], 
                [...secType, searchSuggestions[index][3]], 
                column, 
                [...sectorSub, searchSuggestions[index][5]]
            ).then(r => {})
    

            // insert value to searched terms
            const newSearchedTerms = [value, ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";

            // push the value to last searches
            if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
                const newSearches = [...lastSearches, value];
                setLastSearches(newSearches);
            }
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            // setVisibleSuggestions(false);

            // console.log([...secType, searchSuggestions[index][3]])
        };

        const addValueForContinuous = (index,) => {
            console.log('search suggestions for continuous ', searchSuggestions, index)
            const value = searchSuggestions[index];
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecId([searchSuggestions[index][1]])
            setKeywords([searchSuggestions[index][0]])
            setSecTypeSub([searchSuggestions[index][2]])
            setSecType([searchSuggestions[index][3]])
            setSector([searchSuggestions[index][4]])
            setSectorSub([searchSuggestions[index][5]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            setExistsInMsFreq([searchSuggestions[index][6]])

            // fetchRightChartDataForContinuous([searchSuggestions[index][1]], [searchSuggestions[index][2]], selectedDataColumn, checkedValuesDefault, checkedValuesDefault, [searchSuggestions[index][0]], [searchSuggestions[index][3]], column, [searchSuggestions[index][5]]).then(r => {
            fetchRightChartDataForContinuous([searchSuggestions[index][1]], [searchSuggestions[index][2]], selectedDataColumn, [searchSuggestions[index][0]], [searchSuggestions[index][3]], column, [searchSuggestions[index][5]]).then(r => {
            })

            // insert value to searched terms
            const newSearchedTerms = [value];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";

            // push the value to last searches
            // if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
            //     const newSearches = [...lastSearches, value];
            //     setLastSearches(newSearches);
            // }
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            // setVisibleSuggestions(false);
        };

        const addValueForFHolder = async (index,) => {
            console.log('search suggestions for f holder ', searchSuggestions, index)
            const value = searchSuggestions[index];
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecId([searchSuggestions[index][1]])
            setKeywords([searchSuggestions[index][0]])
            setSecTypeSub([searchSuggestions[index][2]])
            setSecType([searchSuggestions[index][3]])
            setSector([searchSuggestions[index][4]])
            setSectorSub([searchSuggestions[index][5]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            setExistsInMsFreq([searchSuggestions[index][6]])

            fetchRightChartDataForFHolder([searchSuggestions[index][1]], [searchSuggestions[index][2]], selectedDataColumn, checkedValuesQuarter, [searchSuggestions[index][0]], [searchSuggestions[index][3]], column, [searchSuggestions[index][5]]).then(r => {
            })

            // insert value to searched terms
            const newSearchedTerms = [value];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";

            // push the value to last searches
            // if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
            //     const newSearches = [...lastSearches, value];
            //     setLastSearches(newSearches);
            // }
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            // setVisibleSuggestions(false);
        };


        const addValueFromFavoriteSearch = (index) => {
            // setNotification("הני”ע נוסף למועדפים");
            const value = Object.values(favSearches[index])[0].split(" | ")

            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            let obj = getObjectByIndex(existing_storage, index)
            obj = obj[Object.keys(obj)[0]]
            // console.log(obj)

            let arg = { 
                continuous: [
                    obj.secId,
                    obj.secTypeSub,
                    obj.selectedDataColumn,
                    obj.keywords,
                    obj.secType,
                    obj.column,
                    obj.sectorSub,
                    obj.oneOrTwo,
                    obj.column2,
                    obj.filters,
                    obj.colTab2
                ],
                quarterly: [
                    obj.secId,
                    obj.secTypeSub,
                    obj.selectedDataColumn,
                    obj.checkedValuesMonths,
                    obj.checkedValuesQuarter,
                    obj.keywords,
                    obj.secType,
                    obj.column,
                    obj.sectorSub,
                    obj.oneOrTwo,
                    obj.column2,
                    obj.filters,
                    obj.colTab2
                ],
                f_holder: [
                    obj.secId,
                    obj.secTypeSub,
                    obj.selectedDataColumn,
                    obj.checkedValuesQuarter,
                    obj.keywords,
                    obj.secType,
                    obj.column,
                    obj.sectorSub,
                    obj.oneOrTwo,
                    obj.column2,
                    obj.filters,
                    obj.colTab2
                ]
            }

            const selectFetchChartType = (chartType) => {
                let fetchType;
                setChartType(chartType)
            
                // Determine which fetch function to use
                switch (chartType) {
                    case 'quarterly':
                        fetchType = fetchRightChartData;
                        break;
                    case 'continuous':
                        fetchType = fetchRightChartDataForContinuous;
                        break;
                    case 'f_holder':
                        fetchType = fetchRightChartDataForFHolder;
                        break;
                    default:
                        fetchType = fetchRightChartData;
                }
            
                // Use the determined fetch function
                fetchType(...arg[chartType]).then(r => {
                    setCurrentFavouriteKey(index);
                    setStarActive(true);
                    const result = obj.keywords.map(word => [word]);
                    setSearchedTerms(result);
                    search.value = "";
                    search.placeholder = "";
                    setVisibleSearchPopup(false);
                    setVisibleSuggestions(false);
                    setLastField(false);
                }).catch(error => {
                    console.error("Error fetching chart data:", error);
                });
            };
            
            selectFetchChartType(obj.chartType)
        }

        const addValueFromSimilarSearch = (row) => {
            // console.log(row)
            const value = row
            if (secId.includes(value[0])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                setLastField(false)
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecTypeSub([...secTypeSub, value[5]])
            setSecType([...secType, value[4]])
            setSecId([...secId, value[0]])
            setKeywords([...keywords, value[2]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, value[0]],
                [...secTypeSub, value[5]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, value[2]],
                [...secType, value[4]]
            ).then(r => {
            })
            const newSearchedTerms = [ ...searchedTerms, 
                [
                    value[1],
                    value[0],
                    value[5],
                    value[4],
                    value[6],
                    value[7],
                    false,
                    false
                ]
            ];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            setLastField(false)
        }


        const addValueFromRecentSearch = (index,) => {
            setIsCustomFilter(false) // Incase it is bypassed in the searchInput function
            // // console.log("in a add value")
            

            const value = lastSearches[index]
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                setLastField(false)
                search.value = "";
                search.placeholder = "";
                return
            }
            setSecTypeSub([...secTypeSub, lastSearches[index][2]])
            setSecType([...secType, lastSearches[index][3]])
            setSecId([...secId, lastSearches[index][1]])
            setKeywords([...keywords, lastSearches[index][0]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, lastSearches[index][1]],
                [...secTypeSub, lastSearches[index][2]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, lastSearches[index][0]],
                [...secType, lastSearches[index][3]]
            ).then(r => {
            })
            const newSearchedTerms = [ ...searchedTerms, value];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            setLastField(false)
        }

        const searchClicked = (e) => {
            // console.log('clicked')
            
            // UPDATE SEC_ID & SEC_TYPE AFTER CUSTOM FILTERS
            if (secId?.length === 1 && secId[0] === '') {
                setSecId([]);
                setSecType([]);
            }
            // if (secId?.length === 1 && secId[0] === "") {
            //     console.log('clearall')
            //     clear_all();
            // }

            // TOOGLE SUGGESTION & MULTIPLE SUGGESTION POPUP
            if (secId?.length <= 1 && secId[0] !== "") {
                setVisibleSearchPopup(!visibleSearchPopup);
                setLastField(!lastField);
            } else {
                // console.log('the secId has more ', secId)
                if (e.target.value?.length > 0) {
                    setVisibleSearchPopup(false);
                    setLastField(false);
                    setVisibleSuggestions(true);
                }

                setVisibleSearchPopup(!visibleSearchPopup);
                setLastField(!lastField);
                // if search clicked again hide suggestions
                // if (visibleSuggestions) {
                //     setVisibleSuggestions(false);
                //     setLastField(true);
                // }
            }
        };

        const removeTerm = (index) => {
            // SET VALUES FOR CHECKED MONTHS & QUARTERS TO DEFAULT

            setCheckedValuesQuarter(checkedValuesDefault);
            setCheckedValuesMonths(checkedValuesDefault);
            setValuesForMonthsAndQuarters([1, 1]);
            setNumQuarters(1);

            // END

            const newTerms = searchedTerms.filter((term, i) => i !== (searchedTerms?.length - index - 1));
            setSearchedTerms(newTerms);
            const secid_ = secId.filter((term, i) => i !== index)
            const sectypesub_ = secTypeSub.filter((term, i) => i !== index)
            const sectype_ = secType.filter((term, i) => i !== index)
            const keywords_ = keywords.filter((term, i) => i !== index)
            setSecTypeSub(sectypesub_)
            setSecType(sectype_)
            setSecId(secid_)
            setKeywords(keywords_)
            setActiveClass("hidden")
            if (secid_?.length > 0) {
                if (secid_?.length === 1) {
                    setFilters(filters_start)
                }
                fetchRightChartData(secid_, sectypesub_, selectedDataColumn, checkedValuesDefault, checkedValuesDefault, keywords_).then(r => {
                })
            } else {
                clear_all()
            }


            // if searched terms is empty, display make input field empty
            if (newTerms?.length <= 0) {
                search.value = "";
                // search.placeholder = "search by sec_id/sec_name, issuer, sector, indices";
                search.placeholder = "חפש ני”ע, מדדים, ענפים, מנפיקים";
                setFilters(filters_start)
                setIsCustomFilter(true)
            } else {
                search.placeholder = "הוסף ני”ע להשוואה";
                setIsCustomFilter(false)
            }
        };

        const clear_all = () => {
            fetchDataInit()
            setLoading(false)
            setSearchedTerms([])
            setSeries([])
            setLabelsData([])
            setLeftChartMsg(false)
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setGrandSum(0)
            setSecId([])
            setSecTypeSub([])
            setSecType([])
            setSector([])
            setSectorSub([])
            setToShowDuration(false)
            setToShowCap(false)
            setFilters(filters_start)
            setCustomFilters(custom_filters_start)
            setColumn2('holders')
            setColTab2('holders')
            setRelatedSecurities([])
            setDisplay(false)
            setStarActive(false)

        }


        if (appLoading) {
        return <div className="h-[93vh] flex justify-center items-center">
            <DrorLoader />
        </div>;
        }

        return (
            <>
                <div className="w-[100%] ml-auto p-3 mr-1 pb-0">
                    <div className="w-[100%] lg:w-[90%] max-w-[1200px] relative ml-auto w-full flex justify-between items-center rounded">
                        <Contact open={contactOpen} setOpen={setContactOpen} isMobile={isMobile} />
                        <Faqs open={faqOpen} setOpen={setFaqOpen} isMobile={isMobile} />
                        <Alerts 
                            open={alertOpen} 
                            setOpen={setAlertOpen} 
                            isMobile={isMobile} 
                            setNotification={setNotification} 
                            errorNotification={errorNotification}
                        />
                        <SearchComponent 
                            isMobile={isMobile}
                            toggleSearch={toggleSearch}
                            searchedTerms={searchedTerms}
                            clear_all={clear_all}
                            searchClicked={searchClicked}
                            searchInput={searchInput}
                            removeTerm={removeTerm}
                            visibleSearchPopup={visibleSearchPopup}
                            SearchHistory={SearchHistory}
                            lastSearches={lastSearches}
                            favSearches={favSearches}
                            addValue={addValue}
                            addValueForContinuous={addValueForContinuous}
                            addValueForFHolder={addValueForFHolder}
                            addValueFromSimilarSearch={addValueFromSimilarSearch}
                            addValueFromRecentSearch={addValueFromRecentSearch}
                            addValueFromFavoriteSearch={addValueFromFavoriteSearch}
                            visibleSuggestions={visibleSuggestions}
                            searchSuggestions={searchSuggestions}
                            relatedSecurities={relatedSecurities}
                            related_securities_legend={related_securities_legend}
                            lastField={lastField}
                            handleSearchRelatedSecurity={handleSearchRelatedSecurity}
                            handleAddRelatedSecurity={handleAddRelatedSecurity}
                            relatedSecuritySelectedValues={relatedSecuritySelectedValues}
                            secId={secId}
                            isCustomFilter={isCustomFilter}
                            customFilters={customFilters}
                            custom_filters_onclick={custom_filters_onclick}
                            filter_change={filter_change}
                            filters={filters}
                            title_modal={title_modal}
                            description={description}
                            description_={description_}
                            activeClass={activeClass}
                            setrangecap_custom={setrangecap_custom}
                            toShowDuration={toShowDuration}
                            setrangeduration={setrangeduration}
                            onHoverDur={onHoverDur}
                            setOnHoverDur={setOnHoverDur}
                            toShowCap={toShowCap}
                            onHoverCap={onHoverCap}
                            setOnHoverCap={setOnHoverCap}
                            formatNumber={formatNumber}
                            MultiRangeSlider={MultiRangeSlider}
                            MultiRangeSliderSmall={MultiRangeSliderSmall}
                            setrangecap={setrangecap}
                            cancel={cancel}
                            deleteItem={deleteItem}
                            applyFilters={applyFilters}
                        />

                        {/* <div
                            className="btns flex justify-center items-center w-auto px-2 ml-2 h-[36px] overflow-hidden rounded bg-white">
                            <button onClick={() => getFromLocalStorage(-1)}
                                    className="btn bg-white hover:bg-transparent border-0 p-0 text-black mx-2 text-xl">
                                <FiArrowLeft/>
                            </button>
                            <button onClick={() => getFromLocalStorage(1)}
                                    className="btn bg-white hover:bg-transparent border-0 p-0 text-black mx-2 text-xl">
                                <FiArrowRight/>
                            </button>
                        </div> */}
                        
                    </div>
                    <div id="relatedCorpId" dir="rtl" className="overflow relative flex align-items text-left text-sm mt-3 text-white flex justify-start align-items-center">

                        {/*{relatedCorpsIds.map((row, index) => (*/}
                        {/*    <p*/}
                        {/*    key={index}*/}
                        {/*    onContextMenu={(event) => {*/}
                        {/*        event.preventDefault();*/}
                        {/*        handleRelatedCorpIDMultiSearch(index);*/}
                        {/*    }}*/}
                        {/*    title="Right click for multiple search Left click for new search"*/}
                        {/*    onClick={() => handleRelatedCorpID(index)}*/}
                        {/*    style={{whiteSpace: 'nowrap'}}*/}
                        {/*    className="mx-3 text-right cursor-pointer related_corps">*/}
                        {/*    {row[0]}*/}
                        {/*</p>*/}
                        {/*))*/}
                        {/*}*/}
                        
                        {/* <p className="text-right related_corps mx-3 whitespace-nowrap"> : חיפושים קשורים </p> */}
                        <p className="text-right related_corps mx-3 whitespace-nowrap"> חיפושים קשורים לעדכון רבעוני :</p>
                        {/*<p className="text-right related_corps mx-3 whitespace-nowrap"> : Related Search</p>*/}
                        
                        {relatedCorpsIds?.slice(0, visibleRelativeCorpIdCount).map((row, index) => (
                            <p dir="rtl"
                            key={index}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                handleRelatedCorpIDMultiSearch(index);
                            }}
                            onClick={() =>  isMobile 
                                ? (setShowModal(true), setRelatedCorpIdSearchIndex(index)) 
                                : handleRelatedCorpID(index)
                            }
                            className="mx-3 text-right cursor-pointer related_corps"
                            style={{
                                whiteSpace: 'nowrap',
                                position: 'relative'
                            }} // Add position relative for tooltip positioning
                            >
                                {row[0]}
                                <span className="hidden lg:block tooltip">
                                    <span className="tooltip-arrow"></span>
                                    {
                                        // The first related Corp Id is the current search
                                        index === 0 ? null : 
                                        <>
                                            <span className="tooltip-text">קליק ימני - הוסף להשוואה</span>
                                            {/* <span className="tooltip-text">Right click for multiple search</span> */}
                                            <br/>
                                        </>
                                    }
                                    {/*<span className="tooltip-text">Left click for new search</span>*/}
                                    <span className="tooltip-text">קליק שמאלי - חיפוש חדש</span>
                                </span>
                            </p>
                        ))}

                        <RelativeSearchDropdown 
                            visibleRelativeCorpIdCount={visibleRelativeCorpIdCount}
                            relatedCorpsIds={relatedCorpsIds} 
                            handleRelatedCorpID={handleRelatedCorpID}
                            handleRelatedCorpIDMultiSearch={handleRelatedCorpIDMultiSearch}
                            isMobile={isMobile}
                        />
                    </div>
                </div>
                <Charts 
                        isMobile={isMobile}
                        chartType={chartType}
                        series={seriesData}
                        secId={secId}
                        searchedTerms={searchedTerms}
                    // allCategories = {allCategories}
                        labels_data={labelsData}
                        num={labelsDataNum}
                        handleRowClick={handleRowClick}
                        allLabelsNames={allLabelsNames}
                        allQuarterNames={allQuarterNames}
                        bar_monthly_data={barMonthlyData}
                        bar_monthly_categories={barMonthlyCategories}
                        bar_quarterly_categories={barQuarterlyCategories}
                        bar_quarterly_data={barQuarterlyData}
                        grandSum={grandSum}
                        selectedRowId={selectedRowId}
                        handleCustomDropdownTop={handleCustomDropdownTop}
                        dates={dates}
                        handleCheckMonth={handleCheckMonth}
                        handleCheckQuarter={handleCheckQuarter}
                        checkedValuesMonths={checkedValuesMonths}
                        checkedValuesQuarter={checkedValuesQuarter}
                        valuesForMonthsAndQuarters={valuesForMonthsAndQuarters}
                        handleFilterMonthsAndQuarters={handleFilterMonthsAndQuarters}
                        display={display}
                        legands={lagends}
                        num_quarters={num_quarters}
                        loading={loading}
                        loadingleft={loadingLeft}
                        handleCustomDropdown={handleCustomDropdown}
                        customDropdown={customDropdown}
                        num_multi_search={secId?.length}
                        selected_data_column={selectedDataColumn}
                        rightChartCatagories={rightChartCatagories}
                        secType={secType}
                        handleSelectionChange_={handleSelectionChange}
                        column={column}
                        keywords={keywords}
                        column2={column2}
                        handleSelectionChange2_={handleSelectionChange2}
                        activate_star_function={activate_star_function}
                        starActive={starActive}
                        isCustomFilter={isCustomFilter}
                        upper_chart_msg={left_chart_msg}
                        searchedSecName={keywords[0]}
                        sec_id_search={sec_id_search}
                        setNotification={setNotification}
                />
                
                {notification && (
                    <Notification
                    type='success'
                    message={notification}
                    duration={2500}
                    onClose={() => setNotification(null)}
                    />
                )}
                
                {errorNotification && (
                    <Notification
                    type='error'
                    message={errorNotification}
                    duration={2500}
                    onClose={() => setErrorNotification(null)}
                    />
                )}
                
                <SearchModal
                    show={showModal}
                    searchIndex={relatedCorpIdSearchIndex}
                    handleRelatedCorpID={handleRelatedCorpID}
                    handleRelatedCorpIDMultiSearch={handleRelatedCorpIDMultiSearch}
                    onClose={() => setShowModal(false)}
                    isMobile={isMobile}
                />
            </>
        );
    }
;

export default Search;
