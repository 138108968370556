import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
// import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import axios from "axios";
import { BASE_URL } from '../../../constants';
import logo from '../../../assets/images/brokersconnect_logo.jpeg';

const SignupPage = ({handleLogin}) => {
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        jobTitle: "",
        companyNameUser: "",
        passwordUser: "",
        passwordUserConfirm: "",

    });
    const [loading, setLoading] = useState(false);
    // const [isMobile, setIsMobile] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const navigate = useNavigate();
    const [policyAgreement, setPolicyAgreement] = useState(false);
    const policyAgreementRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        });

        // Save to storage (but not password)
        localStorage.setItem('regUser', JSON.stringify({
            ...user,
            passwordUser: "",
            passwordUserConfirm: "",
        }));
    };

    const handleCheckboxChange = (e) => {
        if (policyAgreement) {
            setPolicyAgreement(false);
            policyAgreementRef.current.setCustomValidity('חובה לאשר את תנאי השימוש עבור רישום למערכת');
        } else {
            setPolicyAgreement(true);
            policyAgreementRef.current.setCustomValidity('');
        }
    };

    const HandleSignupUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setPhoneNumberError(false);
        setJobTitleError(false);
        setCompanyNameError(false);
        setPasswordError(false);
        setPasswordConfirmError(false);

        // Validation checks
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

        let hasError = '';

        if (!user.firstName) {
            setFirstNameError(true);
            hasError = 'firstName';
        }
        if (!user.lastName) {
            setLastNameError(true);
            hasError = 'lastName';
        }
        if (!user.email || !emailRegex.test(user.email)) {
            setEmailError(true);
            hasError = 'email';
        }
        if (!user.phoneNumber) {
            setPhoneNumberError(true);
            hasError = 'phoneNumber';
        }
        if (!user.jobTitle) {
            setJobTitleError(true);
            hasError = 'jobTitle';
        }
        if (!user.companyNameUser) {
            setCompanyNameError(true);
            hasError = 'companyNameUser';
        }
        if (!user.passwordUser || !passwordRegex.test(user.passwordUser)) {
            setPasswordError(true);
            hasError = 'passwordUser';
        }
        if (user.passwordUser !== user.passwordUserConfirm) {
            setPasswordError(true);
            setPasswordConfirmError(true);
            hasError = 'passwordUserConfirm';
        }

        if (!policyAgreement || hasError) {
            if (hasError === 'email') {
                Swal.fire({
                    title: 'אימייל לא תקין',
                    text: 'אנא הזן כתובת דוא"ל תקנית',
                    icon: 'warning',
                    confirmButtonText: 'סגור'
                })
                return
            }
            if (hasError === 'passwordUser') {
                Swal.fire({
                    title: 'סיסמה חלשה',
                    text: 'עליך לבחור סיסמה בעלת 8 תווים, הכוללת מספרים, אותיות ואות גדולה אחת לפחות',
                    icon: 'warning',
                    confirmButtonText: 'סגור'
                })
                return
            }
            if (hasError === 'passwordUserConfirm') {
                Swal.fire({
                    title: 'סיסמה שאינה תואמת',
                    text: 'הסיסמה שלך אינה תואמת',
                    icon: 'warning',
                    confirmButtonText: 'סגור'
                })
                return
            }
            return; // Exit the function if there are any errors
        }
        
        const userData = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            phone_number: user.phoneNumber,
            job_title: user.jobTitle,

            company_name: user.companyNameUser,
            password: user.passwordUser
        };

        try {
            // Remove user from storage
            localStorage.setItem('regUser', JSON.stringify({}));
            const response = await axios.post(`${BASE_URL}/accounts/register/user/`, {
                ...userData,
                disabled: true
            })

            if (response.status === 200 || response.status === 201) {
                // Route to home after successful registration
                Swal.fire({
                    title: 'רשימת המתנה',
                    text: 'נוספת לרשימת ההמתנה, נעדכן אותך בקרוב לגבי פרטי הגישה למערכת',
                    icon: 'info',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'סגור'
                })
                
                navigate('/');
            } else {
                console.error("Unexpected server response:", response.data);
                // Handle any other server responses here, you can add more conditions to check for other status codes
            }
        } catch (error) {
            console.log("error")
            console.log(error)
            if (error.response && error.response.status === 400) {

                // FOR IF EMAIL IS ALREADY IN USE
                Swal.fire({
                    title: 'רישום',
                    // text: "לא ניתן להשלים את הרישום עבור מייל זה. אנא פנה לתמיכה בכתובת support@brokersconnect.co.il",
                    html: `
                        <div>
                            <p>לא ניתן להשלים את הרישום עבור מייל זה. </p>
                            <p>אנא פנה לתמיכה בכתובת</p>
                            <p>support@brokersconnect.co.il</p>
                        </div>`,
                    icon: 'error',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'סגור'
                })
            }
            else if (error.response && error.response.status === 403) {
                // IF NOT BUSINESS EMAILS
                Swal.fire({
                    text: 'הרישום לשלב הבטא מוגבל לגופים מוסדיים וחברות ציבוריות עם מייל ארגוני בלבד.',
                    icon: 'info',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'סגור'
                })
            } else {
                Swal.fire({
                    title: error.code,
                    text: error.message,
                    icon: 'error',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'סגור'
                })
            }


        }
        setLoading(false);
    };
          
    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(window.innerWidth <= 1024); // Adjust the width as needed
    //     };
    
    //     // Initial check
    //     handleResize();
    
    //     // Add event listener for window resize
    //     window.addEventListener('resize', handleResize);
    
    //     // Clean up event listener on component unmount
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    useEffect(() => {
        // Set custom validity message on page load
        policyAgreementRef.current.setCustomValidity('חובה לאשר את תנאי השימוש עבור רישום למערכת');
    }, []);

    useEffect(() => {
        // Get user details
        const item = localStorage.getItem('regUser');
        if (item === null) {
            localStorage.setItem('regUser', JSON.stringify({}));
        } else {
            try {
                const regUser = JSON.parse(item);

                // Check for valid object 
                if (typeof regUser === 'object' && regUser !== null && Object.keys(regUser).length !== 0) {
                    setUser(regUser);
                }
            } catch (error) {
                console.error("Failed to parse reg user from localStorage", error);
                // Reset the localStorage item if parsing fails
                localStorage.setItem('regUser', JSON.stringify({}));
            }
        }
    }, []);

    // BUTTON SCROLL STATE MANAGEMENT

    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const sectionRef = useRef(null);
    const scrollableElementRef = useRef(null);

    // Scroll to the section when the button is clicked
    const handleButtonClick = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsButtonVisible(false); // Hide the button after clicking
        }
    };

    // Handle scroll event to detect when the section comes into view and hide button on manual scroll
    const handleScroll = () => {
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const elementHeight = scrollableElementRef?.current?.clientHeight;

        // console.log('THE SETUP - ', sectionTop, scrollableElementRef.current, elementHeight, window.scrollY)
        // Check if the section is in view
        if (sectionTop <= elementHeight) {
            setIsButtonVisible(false); // Hide the button when the section is in view
        } else {
            setIsButtonVisible(true); // Show the button when the section is not in view
        }

        // Hide button when scrolling manually past a certain threshold
        if (window.scrollY > 100) {
            setIsButtonVisible(false); // Hide button after manually scrolling
        }
    };

    useEffect(() => {
        const scrollableElement = scrollableElementRef.current;
        // Add the scroll event listener
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);

            // Clean up the event listener on component unmount
            return () => {
                scrollableElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <>
            <div ref={scrollableElementRef} style={{fontFamily:"open sans hebrew"}} className="px-4 overflow-y-scroll xl:overflow-hidden xl:flex items-center h-[97vh] w-[100%] justify-center bg-transparent pb-[16px] xl:pb-0">
                <div dir="rtl" className="flex flex-col justify-center w-full my-2 xl:px-5 xl:flex xl:flex-row">
                    <div className="mt-[50px] xl:m-auto xl:w-1/2 my-auto text-white mb-[50px] xl:mb-0">
                        <div style={{maxWidth:600}} className="mx-auto" >
                            <img src={logo} className="w-[300px] ml-auto mr-auto mb-[50px] xl:mb-6" alt="brokersconnect logo"/>
                            <h2 className="text-lg pr-[20px] font-bold mb-4">המערכת המתקדמת בישראל לניתוח אחזקות מוסדיים בניירות ערך</h2>
                            
                            <ul className="list-disc list-inside mb-8">
                                <li className="text-white pr-[20px] indent-[-20px] text-sm mb-4">עדכוני אחזקות רציפים מעבר לדיווח הרבעוני עבור מניות ואג"ח ישראלים</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">עדכונים רבעוניים מידיים עבור דיווחי מוסדיים מוקדמים ללא צורך להמתין לסוף הרבעון</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">אחזקות היסטוריות לני"ע סחירים/לא סחירים ואחזקות מוסדיים זרים במניות הדואליות</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">מערכת גרפית מתקדמת לביצוע ניתוחי עומק מורכבים רב רבעוניים והשוואות בין ני"ע שונים</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">הצגה אוטומטית של ני"ע מאותו מנפיק וני"ע דומים על פי פרמטרים נבחרים</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">חיפוש מתקדם של קבוצות ני"ע וניתוח מצרפי על פי פרמטרים נבחרים</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">איתור גופים שאינם מחזיקים בני"ע נבחר וניתוח אחזקות של גופים המחזיקים בני"ע דומים</li>
                                <li className="text-white pr-[20px] indent-[-20px] text-sm font-medium mb-4">ניתוח אחזקות לפי ענפים, מדדים ומנפיקים</li>
                            </ul>

                            <h2 className="text-xl pr-[20px] font-bold mb-4">הצטרף עכשיו לגרסת הבטא ללא תשלום!</h2>
                            <p className="pr-[20px] font-medium mb-4">* שלב הבטא פתוח לגופים מוסדיים וחברות ציבוריות בלבד, הרישום מותנה ברישום עם מייל ארגוני.</p>

                            <div className="pr-[20px] hidden xl:block mb-4">
                                <p className="mb-4">
                                    כבר יש לך חשבון?  <Link to={'/login'} className="orange_font">התחבר</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    

                    <div ref={sectionRef} className="xl:w-1/2 text-white">
                        <div>
                            <form style={{backgroundColor: '#1f1f1f', width:'100%', maxWidth: 600}}
                                className="bg-black px-4 pt-8 pb-2 xl:p-8 shadow-md rounded-md mx-auto"
                                onSubmit={HandleSignupUser}>
                                <h2 className="text-2xl text-center font-bold mb-6">
                                    הרשמה
                                </h2>
                                {/* User form fields */}
                                <div className="xl:flex">
                                    <div className="xl:w-1/2 m-2">
                                        <div className="mb-4">
                                            <label htmlFor="first-name"
                                                className="block text-white text-sm font-bold mb-2">
                                                שם פרטי
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                className={`w-full p-2 ${firstNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.firstName}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setFirstNameError(false)
                                                }}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="email"
                                                className="block text-white text-sm font-bold mb-2">
                                                מייל
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                className={`w-full p-2 ${emailError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.email}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setEmailError(false)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="xl:w-1/2 m-2">
                                        <div className="mb-4">
                                            <label htmlFor="last-name"
                                                className="block text-white text-sm font-bold mb-2">
                                                שם משפחה
                                            </label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                className={`w-full p-2 ${lastNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.lastName}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setLastNameError(false)
                                                }}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="phone-number"
                                                className="block text-white text-sm font-bold mb-2">
                                                סלולרי
                                            </label>
                                            <input
                                                type="tel"
                                                name="phoneNumber"
                                                className={`w-full p-2 ${phoneNumberError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.phoneNumber}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setPhoneNumberError(false)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="xl:flex flex-col">
                                    <div className="xl:flex gap-4 m-2">
                                        <div className="xl:w-1/2 mb-4">
                                            <label htmlFor="job-title"
                                                className="block text-white text-sm font-bold mb-2">
                                                תפקיד
                                            </label>
                                            <input
                                                type="text"
                                                name="jobTitle"
                                                className={`w-full p-2 ${jobTitleError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.jobTitle}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setJobTitleError(false)
                                                }}
                                            />
                                        </div>
                                        
                                        <div className="xl:w-1/2 mb-4">
                                            <label htmlFor="company-name-user"
                                                className="block text-white text-sm font-bold mb-2">
                                                חברה
                                            </label>
                                            <input
                                                type="text"
                                                name="companyNameUser"
                                                className={`w-full p-2 ${companyNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.companyNameUser}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setCompanyNameError(false)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="xl:flex gap-4 m-2">
                                        <div className="xl:w-1/2 mb-4">
                                            <label htmlFor="password-user"
                                                className="block text-white text-sm font-bold mb-2">
                                                סיסמה
                                            </label>
                                            <input
                                                type="password"
                                                name="passwordUser"
                                                className={`w-full p-2 ${passwordError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.passwordUser}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setPasswordError(false)
                                                }}
                                            />
                                        </div>

                                        {/* Confirm Password */}
                                        <div className="xl:w-1/2 mb-4">
                                            <label htmlFor="password-user-confirm"
                                                className="block text-white text-sm font-bold mb-2">
                                                הקלד סיסמה לאימות
                                            </label>
                                            <input
                                                type="password"
                                                name="passwordUserConfirm"
                                                className={`w-full p-2 ${passwordConfirmError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                                value={user.passwordUserConfirm}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if (user.passwordUser !== e.target.value) {
                                                        setPasswordConfirmError(true)
                                                    } else {
                                                        setPasswordConfirmError(false)
                                                        setPasswordError(false)
                                                    }
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                    
                                <div className='mx-2'>
                                    <div className="flex items-center gap-1 mb-4">
                                        <input
                                            type="checkbox"
                                            name="policy-agreement"
                                            onChange={handleCheckboxChange}
                                            // className="mr-2"
                                            required
                                            ref={policyAgreementRef}
                                        />
                                        &nbsp;
                                        <label htmlFor="policy-agreement" className="text-white text-sm">
                                            אני מאשר/ת את  <Link to={"/policy"}  className="underline orange_font">תנאי השימוש</Link>  וקבלת פניות שיווקיות
                                        </label>
                                    </div>

                                    <button type="submit"
                                            className="px-5 orange_color text-white p-2 rounded-md text_box_color hover:bg-orange-700 my-[16px] xl:mb-0">
                                        הרשם
                                    </button>
                                </div>

                            </form>
                        </div>
                        <div className='h-[20px] xl:h-[0px]'></div>
                    </div>
                </div>
            </div>
            
            {isButtonVisible && 
                <button
                    disabled={loading}
                    onClick={handleButtonClick}
                    className="xl:hidden fixed bottom-0 w-full p-5 orange_color text-white text-2xl font-bold text_box_color hover:bg-orange-700">
                    הרשם עכשיו
                </button>
            }
        </>
    );
};

export default SignupPage;
