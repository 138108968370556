import React, { useLayoutEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {formatNumber} from "../../utils"
import {convertDateFormat2, mapEnglishToHebrew} from "../utils";

const ApexChart = React.memo((
    {
        data,
        numBins,
        legands,
        rightChartCatagories,
        selected_data_column,
        num_quarters,
        num_multi_search,
        chartType,
        column2,
        secType
    }) => {
    const addZeroToFirstToAccountForSumAll = (data) => {
        const newArray = [...data].map((item) => {
            if (Array.isArray(item.data)) {
                if (chartType === 'quarterly' || chartType === 'continuous') {
                    return { 
                        ...item, 
                        data: [0, ...item.data], 
                        date: ['', ...item.date] 
                    };
                } else {
                    return { 
                        ...item, 
                        data: [0, ...item.data] 
                    };
                }
            // return { ...item, data: [0, ...item.data] }; // Create a new object with updated data
            }
            return item; // Return the original item if data is not an array
          });
        return newArray
    }

    const addToFirstToRightChartCategoriesSanitized = (categories) => {
        return ['', ...categories]
    }
    

    // Replace any undefined OR null columns with N/A
    const rightChartCategoriesSanitized = rightChartCatagories.map((cat) =>
        cat === undefined || null ? "N/A" : cat
    );
    const [chartColor, setChartColor] = useState(["#E76D3B", "#2DBDA8", "#AA1985", "#84B547", "#7F7F7F",]);
    
    useLayoutEffect(() => {
        if (chartType === 'quarterly') {
            setChartColor(["#E76D3B", "#2DBDA8", "#AA1985", "#84B547", "#7F7F7F",]);
        }
        else if (chartType === 'continuous') {
            setChartColor(["#3F81C3", "#51AF72", "#F76565",]);
        }
        else if (chartType === 'f_holder') {
            setChartColor(["#84B547"]);
        }
    }, [chartType]);

    // useEffect(() => {
    //     const newData = addZeroToFirstToAccountForSumAll(data);
    //     setModifiedData(newData);
    // }, [secType, column2, data, num_multi_search, num_quarters])

    const quarterlyChartTitles = {
        0:"גמל",
        1:"פנסיה",
        2:"ביטוח",
        3:"קרנות נאמנות",
        4: "קרנות מחקות וסל",
        5:"גמל",
        6:"פנסיה",
        7:"ביטוח",
        8:"קרנות נאמנות",
        9: "קרנות מחקות וסל",
    }

    const continuousChartTitles = {
        0: "גמל ביטוח פנסיה",
        1: "קרנות נאמנות, סל ומחקות",
        2: "אחר"
    }

    // const fHolderChartTitle = {
    //     0: "f_holder"
    // }

    const options = {
        chart: {
            type: "bar",
            // width: "780px",
            // height: 950,
            // width: 700,
            stacked: true,
            toolbar: {
                show: false,
            },
            redrawOnParentResize: true

        },
        dataLabels: {
            enabled: true,
            formatter: function (value) {
                if (value === 0) { return '0'};
                if (value >= 1e9) {
                    return (value / 1e9).toFixed(2) + ' B';
                } else if (value >= 1e6) {
                    return (value / 1e6).toFixed(2) + ' M';
                } else if (value >= 1e3) {
                    return (value / 1e3).toFixed(2) + ' K';
                } else if (value < 1) {
                    value = value.toFixed(2)
                } else {
                    value = value.toFixed(2)
                }
                if (selected_data_column === "sum_par_percentage") {
                    return `${value} %`;
                }
                return `${value}`;
            },
            offsetX: selected_data_column === "sum_par_percentage" ? -12 : 0
        },

        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: 20,
                distributed: false, 
                // columnWidth: 50,
                dataLabels: {
                    total: {
                        enabled: false,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        stroke: {
            width: 3,
            colors: ["transparent"],
        },
        colors: chartColor,
        xaxis: {
            show: false,
            categories: column2 === "sectors" || 
                secType[0] === "0" || 
                secType[0] === "15" ||
                num_multi_search > 1 ||
                num_quarters > 1 
                    ? rightChartCategoriesSanitized : addToFirstToRightChartCategoriesSanitized(rightChartCategoriesSanitized),
            labels: {
                formatter: function (val) {
                    if (val === '0' || val === 0) {
                        return null;
                    }
                    if (selected_data_column === "sum_par_percentage") {
                        return val.toFixed(2);
                    }
                    return formatNumber(val);
                },
                style: {
                    colors: "#fff",
                },
                rotate: 50,
                offsetX: -15,
            },
        },
        yaxis: {
            show: false,
            reversed: true,
            opposite: false,
            max: function(max) {
                // console.log('max - ', max, Math.ceil(max))
                if (selected_data_column === "sum_par_percentage") {
                    // Round to nearst .5 or 1
                    // e.g 6.4 -> 6.5, 6.51 -> 7
                    return Math.round(max * 2) / 2;
                } 
                // const magnitude = Math.floor(Math.log10(max));
                // // Extract the first two digits
                // const firstTwoDigits = Math.round(max / Math.pow(10, magnitude - 1));
                // // Reconstruct the rounded number with zeros
                // const roundedNumber = firstTwoDigits * Math.pow(10, magnitude - 1);
                // return roundedNumber;
                return max
            },
            // forceNiceScale: selected_data_column === "sum_per_percentage" ? true : false,
            forceNiceScale: false,
            decimalsInFloat: 0,
            min: 0,
            // max: 1000,
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Customize the tooltip content with a card-like structure
			    // console.log('the series index - ', data, seriesIndex, dataPointIndex, w)		
                if (chartType === 'quarterly') {
                return (
                    `<div class="custom-tooltip">
                    <div style="background-color: ${w.config.colors[seriesIndex]}" class="tooltip-header text-center ">
                        <div class="tooltip-title">${quarterlyChartTitles[seriesIndex%5]}</div>
                    </div>
                    <div class="tooltip-content text-right">
                        <div class="data-point text-right">${w.config.xaxis.categories[dataPointIndex]}</div>
                        <div class="data-point text-right">${convertDateFormat2(w.config.series[seriesIndex]['date'][dataPointIndex])}:תאריך עדכון</div>
                            <div class="value text-right">${series[seriesIndex][dataPointIndex].toLocaleString('en-US', { maximumFractionDigits: selected_data_column === "sum_par_percentage" ? 2 : 0 })} ${mapEnglishToHebrew(selected_data_column)}</div>
                    </div>
                    </div>`
                );
                } else if (chartType === 'continuous') {
                    return (
                        `<div class="custom-tooltip">
                        <div style="background-color: ${w.config.colors[seriesIndex]}" class="tooltip-header text-center ">
                            <div class="tooltip-title">${continuousChartTitles[seriesIndex]}</div>
                        </div>
                        <div class="tooltip-content text-right">
                            <div class="data-point text-right">${w.config.xaxis.categories[dataPointIndex]}</div>
                            <div class="data-point text-right">${convertDateFormat2(w.config.series[seriesIndex]['date'][dataPointIndex])}:תאריך עדכון</div>
                            <div class="value text-right">${series[seriesIndex][dataPointIndex].toLocaleString('en-US', { maximumFractionDigits: selected_data_column === "sum_par_percentage" ? 2 : 0 })} ${mapEnglishToHebrew(selected_data_column)}</div>
                        </div>
                        </div>`
                    );
                } else if (chartType === 'f_holder') {
                    return (
                        `<div class="custom-tooltip">
                        <div style="background-color: ${w.config.colors[seriesIndex]}" class="tooltip-header text-center ">
                            <div class="tooltip-title">${mapEnglishToHebrew('f_holder')}</div>
                        </div>
                        <div class="tooltip-content text-right">
                            <div class="data-point text-right truncate overflow-hidden w-[100%]">${w.config.xaxis.categories[dataPointIndex]}</div>
                            <div class="value text-right">${selected_data_column === "sum_market_cap" ? "$" : ""}${series[seriesIndex][dataPointIndex].toLocaleString('en-US', { maximumFractionDigits: selected_data_column === "sum_par_percentage" ? 2 : 0 })} :${mapEnglishToHebrew(selected_data_column)}</div>
                        </div>
                        </div>`
                    );
                }
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            fontWeight: 'bold',
            position: "top",
            horizontalAlign: "right",
            offsetX: 80,
            inverseOrder: true,
            customLegendItems: legands,
            markers: {
                fillColors: chartColor
            },
            formatter: function (seriesName, {series, seriesIndex, w}) {
                // console.log('the series name = ', w, num_multi_search)
                if (w.globals.series[seriesIndex]) {
                    // Calculate the sum of values for the current legend
                    let value = w.globals.series[seriesIndex].reduce((acc, curr) => acc + curr, 0);
                    value = Math.abs(value)
                    if (value >= 1e9) {
                        value = (value / 1e9).toFixed(2) + ' B';
                    } else if (value >= 1e6) {
                        value = (value / 1e6).toFixed(2) + ' M';
                    } else if (value >= 1e3) {
                        value = (value / 1e3).toFixed(2) + ' K';
                    } else if (value < 1) {
                        value = value.toFixed(2)
                    } else {
                        value = value.toFixed(2)
                    }
                    // if (selected_data_column === "sum_par_percentage") {
                    //     value = value.toString() + " %"
                    // }
                    value = "<br/> "+ value
                    if (num_multi_search > 1) {
                        value = ""
                    }

                    if (seriesName.includes("<br/>")) {
                        return `${mapEnglishToHebrew(seriesName.split("<br/>")[1])} ${value} `;
                    } else if(seriesName.includes("<br />")) {
                        // console.log(seriesName)
                        return `${seriesName.split("<br /> ")[0]} <br/> ${mapEnglishToHebrew(seriesName.split("<br /> ")[1])} ${value} `;
                    }else{
                        return `${mapEnglishToHebrew(seriesName.split("<br /> ")[0])} ${value} `;
                    }
                    }
                if (seriesName.includes("<br/>")) {
                    return mapEnglishToHebrew(seriesName.split("<br/>")[1]);
                } else {

                    return mapEnglishToHebrew(seriesName);
                }
                // return seriesName;
            },
        },
        grid: {
            padding: {
                top: 0,
                bottom: chartType === "f_holder" && num_quarters === 1 ? 41 : 0,
                left: 0,
                right: 0
            },
            borderColor: "#2f2f2f",
            strokeDashArray: 0,
            position: "back",
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5,
            },
        },
    }

    return (
        <div
            id="chart"
            className={`w-full text-black third ${chartType} ${selected_data_column}`}
            style={{ maxWidth: '780px' }} // Optional: you can adjust the max-width as needed
        >
            {/*{display ?*/}
                <ReactApexChart
                    options={options}
                    series={
                        column2 === "sectors" || 
                        secType[0] === "0" || 
                        secType[0] === "15" ||
                        num_multi_search > 1 ||
                        num_quarters > 1 
                            ? (data || [])
                            : (addZeroToFirstToAccountForSumAll(data) || [])
                    }
                    type="bar"
                    height={numBins}
                    width={"100%"}
                />
            {/*: <div></div>}*/}
        </div>

    )
})

export default ApexChart


// let col_name = "sum_market_cap"
// if (opt === "שווי שוק") {
//     col_name = 'sum_market_cap'
// } else if (opt === "אחוז מהון רשום") {
//     col_name = 'sum_par_percentage'
// } else {
//     col_name = "sum_par_value"
// }