import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import './Faqs.css';
import axios from "axios";
import { BASE_URL } from "../../constants";
import { FaMinus, FaPlus } from "react-icons/fa";
import DrorLoader from "../charts/drorLoader/DrorLoader";

const isHTML = (str) => {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(str);
};

const Faqs = ({ open, setOpen, isMobile }) => {
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [faqList, setFaqList] = useState([]);
  const [showAnswers, setShowAnswers] = useState([]);

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleShow = (index) => {
    const defaultShow = new Array(faqList?.length).fill(false);
    if (showAnswers[index] === true) {
      console.log('it is true', defaultShow)
      setShowAnswers(defaultShow);
    } else {
      const newShow = [
        ...defaultShow.slice(0, index),
        true,
        ...defaultShow.slice(index + 1)
      ];
      setShowAnswers(newShow);
    }
  }

  const getFaqs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/support/faqs`);
        setFaqList(response.data.questions);
        setShowAnswers(new Array(response.data.questions?.length).fill(false));
        setLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === true) {
      getFaqs();
    } else {
      setFaqList([]);
      setShowAnswers([]);
      setLoading(true);
    }
  }, [open]);

  // useEffect(() => {
  //     const handleResize = () => {
  //         setIsMobile(window.innerWidth <= 1024); // Adjust the width as needed
  //     };
  
  //     handleResize();
  
  //     // Add event listener for window resize
  //     window.addEventListener('resize', handleResize);
  
  //     // Clean up event listener on component unmount
  //     return () => window.removeEventListener('resize', handleResize);
  // }, [isMobile]);

  return (
    <div className="faqs lg:relative inline-block text-left lg:mr-2">
      <div className="hidden lg:block ">
        <button
            onClick={handleOpen}
            className="overflow-hidden bg-[#66b8d7] py-1 px-2 rounded text-[#2A2A2A] h-[36px] w-[150px] text-[16px] font-medium text-gray-900 shadow-sm font-bold whitespace-nowrap"
        >
          {/* popular questions */}
          שאלות נפוצות
        </button>
      </div>

      {/*       
        Dropdown menu, show/hide based on menu state.

        Entering: "transition ease-out duration-100"
          From: "transform opacity-0 scale-95"
          To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
          From: "transform opacity-100 scale-100"
          To: "transform opacity-0 scale-95"
      */}

      {open && (
        <>
          {
            isMobile ?
              <FaqContentMobile 
                showAnswers={showAnswers}
                handleShow={handleShow}
                loading={loading}
                faqList={faqList}
                setOpen={setOpen}
                isMobile={isMobile}
              />
            :
            <FaqContent 
              showAnswers={showAnswers}
              handleShow={handleShow}
              loading={loading}
              faqList={faqList}
            />
          }
        </>
      )}
    </div>

  )
}

export default Faqs;


const FaqContent = ({ showAnswers, handleShow, loading, faqList }) => {
  return (
    <div className="accordion absolute right-0 z-10 mt-2 w-[350px] origin-top-right rounded bg-[#232323] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      {
        loading !== true ?
          <>
            {/* <div className="accordion-item px-4 py-4 mb-2">
              <a className="accordion-intro-h block text-white">ask free questions and send to brokersconnect</a>
            </div> */}
            {faqList?.map((item, index) => (
              <div
                key={index}
                className="accordion-item px-4 py-4 mb-2"
                onClick={() => handleShow(index)}
              >
                <div className="flex items-center justify-between gap-4">
                  <a className="block text-sm text-white">{item.title}?</a>
                  {showAnswers[index] === false ? (
                    <FaPlus className="accordion-icon" />
                  ) : (
                    <FaMinus className="accordion-icon" />
                  )}
                </div>
                {showAnswers[index] === true ? (
                  isHTML(item.answer) ? (
                    // Render as HTML if the answer contains HTML tags
                    <div
                      className="accordion-answer"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }}
                    />
                  ) : (
                    // Render as plain text if the answer does not contain HTML tags
                    <div className="accordion-answer"><p>{item.answer}</p></div>
                  )
                ) : null}
              </div>
            ))}
          </>
          :
          <div className="px-4 py-4 h-[100px]">
            <DrorLoader />
          </div>
      }
    </div>
  )
}

const FaqContentMobile = ({ showAnswers, handleShow, loading, faqList, setOpen, isMobile }) => {
  return (
    <div 
      style={{ zIndex: 99999 }}
      className="fixed top-0 p-6 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-90"
    >
      <div className="accordion-mobile static right-0 z-10 mt-2 origin-top-right rounded bg-[#232323] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {
          loading !== true ?
            <>
              <div dir="rtl" className="px-4 py-4 mt-8 mb-2">
                <h2 className="accordion-intro-h text-right block text-white">שאלות נפוצות</h2>
              </div>
              {faqList?.map((item, index) => (
                <div
                  key={index}
                  // className="accordion-item px-4 py-4 mb-2"
                  className={`accordion-item px-4 py-4 ${faqList?.length === index + 1 ? 'mb-4' : 'mb-2'}`}
                  onClick={() => handleShow(index)}
                >
                  <div className="flex items-center justify-between gap-4">
                    <a className="block text-sm text-white">{item.title}?</a>
                    {showAnswers[index] === false ? (
                      <FaPlus className="accordion-icon" />
                    ) : (
                      <FaMinus className="accordion-icon" />
                    )}
                  </div>
                  {showAnswers[index] === true ? (
                    isHTML(item.answer) ? (
                      // Render as HTML if the answer contains HTML tags
                      <div
                        className="accordion-answer"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }}
                      />
                    ) : (
                      // Render as plain text if the answer does not contain HTML tags
                      <div className="accordion-answer"><p>{item.answer}</p></div>
                    )
                  ) : null}
                </div>
              ))}
              
              <button
                className="w-full mt-[auto] orange_color text-white p-2 rounded-md hover:orange_color"
                onClick={() => {setOpen(false)}}
              >
                סגור
              </button>
            </>
            :
            <div className="flex items-center justify-center px-4 py-4 h-[100%]">
              <DrorLoader />
            </div>
        }
      </div>
    </div>
  )
}