import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import '../../index.css';

const TopBar = ({ isLoggedIn, onFaqClick, onContactClick, onAlertClick, onSearchClick, onLoginClick, onLogoutClick }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    onSearchClick();
    navigate('/');
  };

  const openFaq = () => {
    onFaqClick();
    setNavOpen(false);
  }

  const openContact = () => {
    onContactClick();
    setNavOpen(false);
  }

  const openAlert = () => {
    onAlertClick();
    setNavOpen(false);
  }

  const handleLoginClick = () => {
    onLoginClick();
    isLoggedIn ? navigate('/') : navigate('/login');
  };

  const [navOpen, setNavOpen] = useState(false)

  const toggleMobileNav = () => {
    setNavOpen(!navOpen);
  };


  return (
    <>
      <div style={{fontSize:11}} className='font-bold top-bar h-[3vh] py-3 px-4 flex justify-between items-center'>
        <div className='logo'>
          <p className='text-white text-md'>BrokersConnect</p>
        </div>
        <nav dir="rtl" className='nav hidden lg:block'>
          <ul className='flex space-x-4'>
            {/* <li>
              <button
                onClick={handleHomeClick}
                className='text-white hover:underline focus:outline-none'
              >
                עמוד בית
              </button>
            </li> */}
            {
              isLoggedIn ? null :
              <li>
                <button
                  onClick={handleLoginClick}
                  className='text-white hover:underline focus:outline-none'
                >
                  התחבר
                </button>
              </li>
            }
            {isLoggedIn && (
              <li>
                <button
                  onClick={onLogoutClick}
                  className='text-white hover:underline focus:outline-none'
                >
                  התנתק
                </button>
              </li>
            )}
          </ul>
        </nav>

        {isLoggedIn ? 
          <nav className='lg:hidden'>
            <button>
              <MenuOutlinedIcon onClick={toggleMobileNav}/>
            </button>
          </nav> : null}
      </div>
      
      <Drawer
        anchor={'top'}
        open={navOpen}
        onClose={toggleMobileNav}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            color: 'white',
            borderRadius: 0,
            top: 30,
          },
        }}
        ModalProps={{
          BackdropComponent: null,
        }}
        className='lg:hidden'
      >
        <div dir='rtl' className='flex flex-col items-start py-[20px] px-0 gap-[8px] overflow-hidden'>
          <li>
            <a
              onClick={handleHomeClick}
              className='text-black hover:underline focus:outline-none'
            >
              אחזקות מוסדיים
              {/* Institutional holdings */}
            </a>
          </li>
          <li>
              <a
              onClick={openFaq}
              className='text-black hover:underline focus:outline-none'
            >
              שאלות נפוצות
              {/* FAQs */}
            </a>
          </li>
          <li>
            <a
              onClick={openAlert}
              className='text-black hover:underline focus:outline-none'
            >
                הוסף התראה
                {/* Alerts */}
            </a>
          </li>
          <li>
            <a
              onClick={openContact}
              className='text-black hover:underline focus:outline-none'
            >
                כתוב לנו
                {/* Contact */}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                toggleMobileNav();
                onLogoutClick();
              }}
              className='text-black hover:underline focus:outline-none'
            >
              התנתק
              {/* Logout */}
            </a>
          </li>
        </div>
      </Drawer>
    </>
  );
}

export default TopBar;
