import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import axios from 'axios';
import Search from './components/search';
import TopBar from './components/topbar';
import Login from './components/pages/login/login';
import SignUp from './components/pages/signup/signup';
import Holdings from "./components/holdings";
import Policy from './components/pages/policy/policy';
import ResetPasswordPage from './components/pages/login/ResetPassword';
import ActivationPage from './components/pages/login/ActivationPage.js';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [activeComponent, setActiveComponent] = useState('search');
    const [contactOpen, setContactOpen] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            setIsLoggedIn(true);
        }
    }, [isLoggedIn]);

    const handleLogin = () => {
        setIsLoggedIn(true);
        setActiveComponent('search');
    };

    const handleLogout = async () => {
        try {
            const accessToken = sessionStorage.getItem('accessToken'); // or localStorage.getItem('accessToken');
            if (accessToken) {
                await axios.post('/accounts/logout/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                });
                sessionStorage.clear()
                console.log('No access token found');
            }
    
            setActiveComponent('login');
            setIsLoggedIn(false);

        } catch (err) {
            console.log('Error logging out:', err);
        }
    };

    useEffect(() => {
        console.log('THE OPEN - ', faqOpen, contactOpen)
    }, [faqOpen, contactOpen])

    // UNCOMMENT THE IF BLOCK FOR PRODUCTION FOR NOW
    // COMMENT IN DEVELOPMENT
    // if (isMobile && isLoggedIn) {
    //   handleLogout();
    // }
    
    useEffect(() => {
        const handleCheckMobile = () => {
            setIsMobile(window.innerWidth < 1024); // Adjust the width as needed
        };
        handleCheckMobile();
        window.addEventListener('resize', handleCheckMobile);
        
        return () => window.removeEventListener('resize', handleCheckMobile);
    }, [isMobile]);
    
    return (
        <Router>
            <div className="App">
                <TopBar
                    isLoggedIn={isLoggedIn}
                    onContactClick={() => setContactOpen(!contactOpen)}
                    onAlertClick={() => setAlertOpen(!alertOpen)}
                    onFaqClick={() => setFaqOpen(!faqOpen)}
                    onSearchClick={() => setActiveComponent('search')}
                    onLoginClick={() => setActiveComponent('login')}
                    onLogoutClick={handleLogout}
                />
                <main className="main grid grid-cols-1 grid-rows-[80px, auto] lg:grid-rows-[30px,80px,auto]">
                    <Routes>
                        <Route
                            path="/policy"
                            element={<Policy />}
                        />
                        <Route
                            path="/reset-password/:uidb64/:token"
                            element={<ResetPasswordPage />}
                        />
                        <Route
                            path="/activate/:uidb64/:token"
                            element={<ActivationPage />}
                        />

                        <Route
                            path="/"
                            element={isLoggedIn ? (
                                <>
                                    <Holdings/>
                                    <Search 
                                        isMobile={isMobile}
                                        contactOpen={contactOpen} 
                                        faqOpen={faqOpen} 
                                        setContactOpen={setContactOpen}
                                        setFaqOpen={setFaqOpen}
                                        alertOpen={alertOpen}
                                        setAlertOpen={setAlertOpen}
                                        handle_logout={handleLogout}
                                    />
                                </>
                            ) : (
                                <Navigate to="/login"/>
                            )}
                        />

                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    element={(*/}
                        {/*        <>*/}
                        {/*            <Holdings/>*/}
                        {/*            <Search/>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Route
                            path="/login"
                            element={
                                !isLoggedIn ? (
                                    <Login handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/"/>
                                )}
                        />
                        
                        <Route
                            path="/signup"
                            element={
                                !isLoggedIn ? (
                                    <SignUp handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/search"/>
                                )}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/login"/>}
                        />
                    </Routes>
                </main>
            </div>
        </Router>
    );
    
}


export default App;
