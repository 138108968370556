import React, { useState } from 'react';

const Modal = ({ show, onClose, onSubmit, errorType, isMobile, isSubscriberScreen }) => {
  const [data, setData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    licenseCount: "",
    freeText: ""
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data, setData, onClose);
    // onClose();
  };

  if (!show) return null;

  else if (show && isMobile) {
    return (
      <div style={{fontSize:11}} className="fixed px-[32px] top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div style={{backgroundColor:'#1f1f1f', fontFamily:"open sans hebrew"}}
              className="p-8 shadow-md rounded-md rounded-xl text-white w-[100%] max-w-[500px]"
        >
          <h2 className="text-xl text-right font-bold mb-6">בשלב הבטא המערכת תומכת במחשבים שולחניים וניידים בלבד</h2>
          <button
            type="button"
            onClick={onClose}
            className="mt-4 w-full bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
          >
            סגור
          </button>
        </div>
      </div>
    )
  }

  else if (show && errorType === 'multiple-accounts-detected') {
    return (
      <div style={{fontSize:11}} className="fixed px-[32px] top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div style={{backgroundColor:'#1f1f1f', fontFamily:"open sans hebrew"}}
              className="p-8 shadow-md rounded-md rounded-xl text-white w-[100%] max-w-[500px]"
        >
          <h2 className="text-xl text-right font-bold mb-6">המערכת פתוחה במחשב נוסף, על מנת לפתוח את המערכת במחשב זה עליך לנתק את המחשב הנוסף</h2>
          <button
            type="button"
            onClick={onClose}
            className="mt-4 w-full bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
          >
            סגור
          </button>
        </div>
      </div>
    );
  }

  else if (show && isSubscriberScreen) {
    return (
      <div style={{fontSize:11}} className="fixed px-[32px] top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <form style={{backgroundColor:'#1f1f1f', fontFamily:"open sans hebrew"}}
              className="p-8 shadow-md rounded-md rounded-xl text-white w-[100%] max-w-[500px]"
              onSubmit={handleSubmit}
        >
          <h2 className="text-xl text-right font-bold mb-6">לצפייה בנתונים יש להירשם כמנוי</h2>
          <p className="mb-4 text-right">פנה אלינו לקבלת הצעה</p>

          <div className="mb-4">
            <label htmlFor="fullName" className="block text-sm font-bold mb-2">שם מלא</label>
            <input
                required
              type="text"
              id="fullName"
              className="w-full p-2  rounded-md text_box_color"
              value={data.fullName}
              onChange={(e) => setData({...data, fullName: e.target.value})}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-bold mb-2">מייל</label>
            <input
                required
              type="email"
              id="email"
              className="w-full p-2  rounded-md text_box_color"
              value={data.email}
              onChange={(e) => setData({...data, email: e.target.value})}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-sm font-bold mb-2">סלולרי</label>
            <input
                required
              type="text"
              id="phoneNumber"
              className="w-full p-2  rounded-md text_box_color"
              value={data.phoneNumber}
              onChange={(e) => setData({...data, phoneNumber: e.target.value})}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="licenseCount" className="block text-sm font-bold mb-2">מספר רישיונות</label>
            <select
                required
              id="licenseCount"
              className="w-full p-2  rounded-md text_box_color"
              value={data.licenseCount}
              onChange={(e) => setData({...data, licenseCount: e.target.value})}
            >
              {[1,2,3,4,5,6,7,8,9,10].map(num => <option key={num} value={num}>{num}</option>)}
              <option value="10+">10+</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="freeText" className="block text-sm font-bold mb-2">כתוב לנו</label>
            <textarea
                required
              id="freeText"
              rows="4"
              className="w-full p-2  rounded-md text_box_color"
              value={data.freeText}
              onChange={(e) => setData({...data, freeText: e.target.value})}
            />
          </div>

          <button
            type="submit"
            className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
          >
            שלח
          </button>

          <button
            type="button"
            onClick={onClose}
            className="mt-4 w-full bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
          >
            סגור
          </button>
        </form>
      </div>
    );
  }

  else {
    return null;
  }
};

export default Modal;
