import React, { useState, useEffect } from 'react';
import './Notification.css';

const Notification = ({ type, message, duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isHiding, setIsHiding] = useState(false);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsHiding(true); // Trigger the hide animation
        setTimeout(() => {
          setIsVisible(false);
          onClose();
        }, 500); // Match the duration of the slide-out animation
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose, isVisible]);

  return (
    <div
      className={`notification ${isVisible ? (type === 'success' ? 'show success' : 'show error') : 'hide'}`}
    >
      <p>{message}</p>
    </div>
  );
};

export default Notification;
