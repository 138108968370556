import React, { useEffect, useState } from "react";
import axios from "axios";
import DrorLoader from "../charts/drorLoader/DrorLoader";
import Swal from "sweetalert2";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import './Alerts.css';
import { BASE_URL } from "../../constants";



const Alert = ({ open, setOpen, setNotification, setErrorNotification }) => {
  const [loading, setLoading] = useState(false);
  const [alertSuggestions, openAlertSuggestions] = useState(true);
  const [data, setData] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [action, setAction] = useState("");

  const handleAlertSearchInput = (e) => {
    if (e.target.value?.length === 0) {
      openAlertSuggestions(false);
      return;
    }
    openAlertSuggestions(true);
    setKeyword(e.target.value);
    getUnsavedAlerts(e.target.value, alertList);
  }

  const getUnsavedAlerts = async (keyword, alertList) => {
    try {
        const data = {
            'keyword': keyword,
            'alert_list': alertList
        }
        const response = await axios.post(`${BASE_URL}/api/search_alert/`, data);
        setData(response.data || []);

    } catch (error) {
        console.log(error);
    }
  };

  const handleAddAlert = (item) => {
    saveAlert(item);
  }

  const handleRemoveAlert = (item) => {
    removeAlert(item);
  }

  const handleOpen = () => {
    setOpen(!open);
  }

  function formatSecIdAndChartType(sec_name, sec_id) {
    return `${sec_name} | ${sec_id}`;
}

  const saveAlert = async (item) => {
      setAction("adding");
      setSaving(true);
      try {
          const response = await axios.post(`${BASE_URL}/support/alert_list/`, {
                  email: sessionStorage.getItem('email'),
                  alert_item: formatSecIdAndChartType(item[0], item[1]),
          });
          setAlertList(response.data.alert_list);
          await getUnsavedAlerts(keyword, response.data.alert_list);
          setNotification("התראה נוספה בהצלחה") // Alert added successfully
      } catch (error) {
          // console.error('Error saving fav:', error);
          setErrorNotification("שגיאה בהוספת התראה") // Error adding alert
      } finally {
        setSaving(false);
        setAction("");
      }
  }

  const removeAlert = async (item) => {
      setAction("removing")
      setSaving(true);
      try {
          const response = await axios.delete(`${BASE_URL}/support/alert_list/`, {
              data: {
                  email: sessionStorage.getItem('email'),
                  alert_item: formatSecIdAndChartType(item[0], item[1]),
              }
          });
          // await getUserSavedAlerts();
          setAlertList(response.data.alert_list)
          setNotification("התראה הוסרה בהצלחה") // Alert removed successfully
      } catch (error) {
          // console.error('Error saving fav:', error);
          setErrorNotification("שגיאה בהסרת ההתראה") // Error removing alert
      } finally {
        setSaving(false);
        setAction("");
      }
  }

  const getUserSavedAlerts = async () => {
    setSaving(true);
    try {
        const response = await axios.get(`${BASE_URL}/support/alert_list/`, {
            params: { email: sessionStorage.getItem('email') }
        });
        setAlertList(response.data.alert_list);
    } catch (error) {
        console.error('Error saving fav:', error);
    } finally {
      setSaving(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open === true) {
      setLoading(true)
      setKeyword("");
      getUserSavedAlerts();
      setAction("");
    } else {
      setAlertList([]);
      setLoading(true);
      openAlertSuggestions(false);
      setKeyword("");
      setAction("");
    }
  }, [open]);

  return (
      <div className="alerts lg:relative inline-block text-left lg:mr-2">
      <div className="hidden lg:block ">
        <button
            onClick={handleOpen}
            className="flex gap-1 items-center overflow-hidden bg-[#BABABA] py-1 px-2 rounded text-[#2A2A2A] h-[36px] w-[150px] text-[16px] font-medium text-gray-900 shadow-sm font-bold whitespace-nowrap"
        >
          <NotificationsNoneOutlinedIcon />
        
          {/* Alerts */}
          הוסף התראה
        </button>
      </div>

      {
        open ?
        <div style={{zIndex: 99999}} className="fixed p-2 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-90">
          <div className="accordion rounded-[10px] right-0 z-10 mt-2 w-[350px] origin-top-right bg-[#232323] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {
              loading !== true ?
                <>
                  <div 
                    style={{fontFamily: "open sans hebrew"}}
                    className="py-8 px-6 w-[100%] text-white relative"
                  >
                    <h2 className="text-xl text-right font-bold mb-2">הוסף התראה</h2>
                    <p className="text-sm text-right text-gray-500 mb-2">התראות עבור מניות ואג”ח קונצרני ישראלי בלבד</p>
                    <p className="text-sm text-right text-gray-500 mb-4">התראה תישלח עבור כל עדכון בני”ע הנבחרים באמצעות הודעת מייל</p>
        
                    <div className="bg-white flex just items-center w-full mb-6 rounded relative">
                      <input
                        type="text"
                        onInput={handleAlertSearchInput}
                        // defaultValue={"search by sec_id/sec_name, issuer, sector, indices"}
                        placeholder="חפש ני”ע"
                        id="alert-search"
                        autoComplete="off"
                        className="p-2 w-full text-right rounded placeholder:text-black text-sm focus:outline-none"
                      />

                      {
                        alertSuggestions && data.length > 0 && (
                          <div dir="rtl" className="absolute top-[40px] w-full overflow-x-auto bg-white p-3 overflow-y-scroll rounded h-[355px]" style={{ scrollbarWidth: "thin"}}>
                            {
                            data && data?.length > 0 ? (
                              !saving ? data && data?.map((alert, index) => (
                                // <div key={index} dir="rtl" style={{ gridTemplateColumns: '1fr repeat(3, max-content)', alignItems: 'center'}} className="grid gap-4 mb-2">
                                <div key={index} dir="rtl" style={{ gridTemplateColumns: '1fr 120px', alignItems: 'center'}} className="grid gap-4 mb-2">
                                    <div title={`${alert[0]}, ${alert[1]}`} className="flex gap-2 sugg-btn">
                                        <p className="flex-1 font-semibold">{alert[1]}</p>
                                        <p className="flex-1 font-semibold w-[100%] max-w-[125px]">{alert[0]}</p>
                                    </div>

                                    <button 
                                        onClick={() => handleAddAlert(alert)} 
                                        className="new_qr_btn"
                                    >
                                        הוסף התראה
                                        {/* Add alert */}
                                    </button>
                                </div>
                              )) : (action === 'adding' ? <DrorLoader /> : null)
                              ) : null
                            }
                          </div>
                        )
                      }
                      
                    </div>



                    <h2 className="text-xl text-right font-bold mb-2">התראות פעילות  </h2>
                    

                    <div dir="rtl" className="overflow-x-auto overflow-x-hidden bg-white p-3 h-[300px] rounded overflow-y-auto" style={{ scrollbarWidth: "thin"}}>
                      {
                        alertList && alertList?.length > 0 ? (
                          !saving ? alertList && alertList?.map((alert, index) => (
                            <div key={index} dir="rtl" style={{ gridTemplateColumns: '1fr 120px', alignItems: 'center'}} className="grid gap-4 mb-2">
                                <div title={`${alert[0]}, ${alert[1]}`} className="flex gap-2 sugg-btn">
                                    <p className="flex-1 font-semibold">{alert[0]}</p>
                                    <p className="flex-1 font-semibold w-[100%] max-w-[125px]">{alert[1]}</p>
                                </div>

                                <button 
                                    onClick={() => handleRemoveAlert(alert)} 
                                    className="new_qr_btn"
                                >
                                    הסר התראה
                                    {/* Remove alert */}
                                </button>
                              </div>
                          )) : (action === 'removing' ? <DrorLoader /> : null)
                        ) : <div className="flex items-center justify-center h-full">
                              <p className="font-semibold text-lg text-black">אין התראות פעילות</p>
                            </div>
                      }
                    </div>
                                    
                    
                    <button
                        className="w-full mt-4 orange_color text-white p-2 rounded-md hover:orange_color"
                        onClick={() => {setOpen(false)}}
                    >
                          סגור
                    </button>
                  </div>
                </>
                :
                <div className="px-4 py-4 h-[100px]">
                  <DrorLoader />
                </div>
            }
          </div>
        </div>
        :
        null
      }
    </div>

  )
}

export default Alert;