import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const items = {
  "holders": 'אחזקה מצרפית',
  "non_holders": 'שאינם מחזיקים',
  "sectors": 'רשימת ני”ע דומים',
  "holders_similar": 'אחזקות בני”ע דומים',
};

const ColumnType = ({
    column2, 
    handleButton2Click, 
    checkVisibleColumn2, 
    checkDisableColumn2,
    isMobile
  }) => {
  const [columnDropdown, setColumnDropdown] = useState(false);
  
  const handleColumnDropdown = () => {
    setColumnDropdown(!columnDropdown);
  }
  
  return (
    <div
      className="lg:hidden bg-white py-0 h-[25px] mx-4 my-2 flex justify-between items-center relative z-[10]">
      <div className="w-full flex items-center justify-between p-0 bg-transparent w-auto">
        <p
            onClick={handleColumnDropdown}
            tabIndex={0}
            className="btn pl-3 bg-transparent hover:bg-transparent text-black border-0"
        >
            {columnDropdown ? <FaCaretUp/> : <FaCaretDown/>}

        </p>
        <p className="text-xs text-black p-2 font-semibold">{items[column2]}</p>
      </div>

        {columnDropdown && (
          <ul dir="rtl" className="custom-dropdown bg-white p-2 rounded text-xs">
           {Object.entries(items).map(([name, text]) => (
              <li
                key={name}
                style={{
                  display: checkVisibleColumn2(name),
                  color: column2 === name ? '#000000' : '#00000080',
                  pointerEvents: checkDisableColumn2(name),
                }}
                onClick={() => handleButton2Click(name)}
                className="cursor-pointer ml-4 text-[#000000]"
              >
                {text}
              </li>
            ))}
          </ul>
        )}
  </div>
  )
}

export default ColumnType;


{/* <div className="h-[45px] rounded bg-[#292E32] w-full select-wrapper"
      onClick={toggleDropdown}
      ref={selectRef}
    >
      <div className="text-center px-2 text-white relative">
        <span className="dropdown-icon"></span>
        <a
            dir="rtl"
            id="selectt"
            className=" bg-transparent border-2 hover:bg-transparent p-0 font-bold"
            style={{
                margin: 0,
                paddingRight: 0,
                paddingBottom: 0,
                border: 0,
                outline: "none",
                fontSize: 14,
                width: "100%"
            }}
        >
          <p className="text-right">{currentValue}</p>
            <p style={{
                position: "relative",
                fontSize: 16,
                fontWeight: "bold"
            }} className="text-right heb font-semibold mb-0">
                {
                  num_multi_search === 1 ?
                  <b>{grandSum}{selected_data_column === "sum_par_percentage" ? "%" : ""}</b> :
                  "0"
                }
            </p>
        </a>
      </div>
      
      {isDropdownOpen && (
        <ul dir="rtl" className="absolute w-[100%] z-[1]">
          {dropdownList.map(item => (
            <li key={item.id}>
              <button 
                className={`dropdown-content menu w-[100%] p-2 shadow bg-black text-right ${currentValue === item.value ? "active" : ""}`}
                onClick={() => handleSelect(item.value)}
                onBlur={() => {console.log('onblurr')}}
              >
                <span>{item.value}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div> */}