import React from 'react';
import './SearchHistory.css';
import { IoCloseOutline } from 'react-icons/io5';

const SearchHistory = ({
  lastSearches,
  favSearches,
  addValueFromRecentSearch,
  addValueFromFavoriteSearch,
  deleteItem
}) => {
    const getFavItemsChartColor = (chartType) => {
        switch (chartType) {
            case 'quarterly':
                return '#e5e5e5';
            case 'continuous':
                return '#b19eef';
            case 'f_holder':
                return '#5aff55';
            default:
                return '#5aff55';
        }
    }
    

  return (
    <div className="search-popup bg-white p-3">
        <div className="flex justify-end items-start gap-1 overflow-x-hidden">
            <div className="search-history flex-wrap justify-end">
                {lastSearches?.map((search, index) => (
                    <div
                        className="bg-[#E5E5E5] px-3 py-1 rounded whitespace-nowrap  text-sm text-black flex justify-center items-center cursor-pointer"
                        key={index}

                    >
                        <IoCloseOutline
                            className="mr-2"
                            onClick={() => deleteItem(index)}
                        />{" "}
                    <span  onClick={() => addValueFromRecentSearch(index)}>{search[0]}</span>
                </div>))}
            </div>
            <span className="w-[115px] fw-semibold text-sm whitespace-nowrap">: חיפושים אחרונים </span>
        </div>
        <div className="flex justify-end items-center mt-2 overflow-x-hidden">
            {/* {[].map((search, index) => ( */}
            {favSearches.map((search, index) => (
                <div
                    style={{ backgroundColor: getFavItemsChartColor(Object.keys(search)[0]) }}
                    className="bg-[#E5E5E5] px-3 py-1 rounded whitespace-nowrap text-sm text-black mr-2 flex justify-center items-center cursor-pointer"
                    key={index}
                >
                    {/*<IoCloseOutline*/}
                    {/*    className="mr-2"*/}
                    {/*    onClick={() => deleteFavItem(index)}*/}
                    {/*/>{" "}*/}
                    <span onClick={() => addValueFromFavoriteSearch(index)}>{Object.values(search)[0]}</span>
                </div>))}
            <span className="w-[115px] fw-semibold text-sm whitespace-nowrap">: מועדפים</span>
        </div>
    </div>
  );
}

export default SearchHistory;