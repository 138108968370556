import {FaTimes} from "react-icons/fa";
import {IoCloseOutline, IoPlaySkipForward} from "react-icons/io5";
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from "react";
import SearchModal from "./searchModal";



const SearchComponent = ({
  isMobile,
  toggleSearch,
  searchedTerms,
  clear_all,
  searchClicked,
  searchInput,
  removeTerm,
  visibleSearchPopup,
  SearchHistory,
  lastSearches,
  favSearches,
  addValue,
  addValueForContinuous,
  addValueForFHolder,
  addValueFromSimilarSearch,
  addValueFromRecentSearch,
  addValueFromFavoriteSearch,
  visibleSuggestions,
  searchSuggestions,
  relatedSecurities,
  related_securities_legend,
  lastField,
  handleSearchRelatedSecurity,
  handleAddRelatedSecurity,
  relatedSecuritySelectedValues,
  secId,
  isCustomFilter,
  customFilters,
  custom_filters_onclick,
  filter_change,
  filters,
  title_modal,
  description,
  description_,
  activeClass,
  setrangecap_custom,
  toShowDuration,
  setrangeduration,
  onHoverDur,
  setOnHoverDur,
  toShowCap,
  onHoverCap,
  setOnHoverCap,
  formatNumber,
  MultiRangeSlider,
  MultiRangeSliderSmall,
  setrangecap,
  cancel,
  deleteItem,
  applyFilters,
}) => {
  return (
  <div className="bg-white mx-1 flex just items-center w-full rounded relative">
    <button
        onClick={toggleSearch}
        className="hidden lg:block bg-[#2A2A2A] ml-1 p-1 rounded text-white w-auto min-w-[125px] text-sm font-bold whitespace-nowrap"
    >
        {/*Advanced search*/}
        חיפוש מתקדם
    </button>
    <div className="px-2 p-1 lg:hidden">
        <TuneIcon
            onClick={toggleSearch}
            style={{ color: '#2A2A2A'}}
        />
    </div>

    {
        searchedTerms?.length !== 0 && (
            <button className="lg:pl-3">
                <IoCloseOutline
                    className="mr-2"
                    onClick={() => clear_all()}
                />
            </button>
        )
    }
    <input
        type="text"
        onClick={searchClicked}
        onInput={searchInput}
        // defaultValue={"search by sec_id/sec_name, issuer, sector, indices"}
        placeholder="חפש ני”ע, מדדים, ענפים, מנפיקים"
        id="search"
        autoComplete="off"
        className="p-2 w-full ml-3 text-right rounded placeholder:text-black text-sm focus:outline-none"
    />

    {/* SEARCH BAR */}
    <div 
        // dir="rtl" 
        className="search-tags flex pr-0 text-sm"
    >
        {searchedTerms && [...searchedTerms].map(
            (term, index) => (
                <span
                    // dir="ltr"
                    key={index}
                    className="bg-[#E5E5E5] px-3 py-1 rounded flex text-xs text-black mr-2 justify-center whitespace-nowrap flex-nowrap items-center"
                >
                <button onClick={() => removeTerm(searchedTerms?.length - index - 1)}>
                    <IoCloseOutline
                        className="mr-2"

                    />
                </button>
                    {" "}
                    {term[0]}
                </span>)
        )}
    </div>

    {/* SEARCH SUGGESTIONS  */}
    {
        // true && (
        visibleSearchPopup && (
            <SearchHistory
                lastSearches={lastSearches}
                favSearches={favSearches}
                addValueFromRecentSearch={addValueFromRecentSearch}
                addValueFromFavoriteSearch={addValueFromFavoriteSearch}
                deleteItem={deleteItem}
            />
        )
    }
    {
        visibleSuggestions && searchSuggestions &&(
            <div dir="rtl" className="search-popup overflow-x-auto lg:overflow-x-hidden bg-white p-3 overflow-y-scroll h-[70vh]" style={{ scrollbarWidth: "thin"}}>
                <div className="text-right min-w-[100%] w-max lg:w-auto">
                    {
                        searchSuggestions && searchSuggestions?.length > 0 ? (
                                searchSuggestions && searchSuggestions?.map((suggestion, index) => (
                                    // <div key={index} dir="rtl" style={{ gridTemplateColumns: '1fr repeat(3, max-content)', alignItems: 'center'}} className="grid gap-4 mb-2">
                                    <div key={index} dir="rtl" style={{ gridTemplateColumns: '1fr 120px 120px max-content', alignItems: 'center'}} className="grid gap-4 mb-2">
                                        <div title={`${suggestion[1]}, ${suggestion[0]}`} className="flex gap-2 sugg-btn">
                                            <p className="flex-1">{suggestion[0]}</p>
                                            <p className="flex-1 w-[100%] max-w-[125px]">{suggestion[1]}</p>
                                        </div>

                                        {/* Return Old Query */}
                                        {/* <button onClick={() => addValue(index)} className="new_qr_btn">quarterly updates</button> */}
                                        <button 
                                            onClick={() => addValue(index)
                                            } 
                                            className="new_qr_btn"
                                        >
                                            עדכון רבעוני
                                        </button>

                                        
                                        {/* Continuous updates if it exists */}
                                        {/* <button disabled={searchSuggestions[index][6] === false ? true : false} className="new_qr_btn">Continuous updates</button> */}
                                        {
                                            searchSuggestions[index][6] === true ?
                                            <button 
                                                onClick={() => addValueForContinuous(index)} 
                                                disabled={searchSuggestions[index][6] === false ? true : false} 
                                                className="new_qr_btn"
                                            >עדכון רציף</button> :
                                            null
                                        }

                                        {/* F_Holder */}
                                            {searchSuggestions[index][7] === true ? 
                                            <button 
                                                onClick={() => addValueForFHolder(index)} 
                                                disabled={searchSuggestions[index][7] === false ? true : false} 
                                                className="new_qr_btn"
                                            >אחזקות זרים</button> : 
                                            null
                                        }


                                        {/* <button
                                            key={index}
                                            className="block mb-2 ml-auto font-semibold w-full text-right sugg-btn w-[50%]"
                                        >
                                        </button> */}
                                    </div>
                                ))
                            )
                            :
                            (
                                <div className="text-center">אין מידע עבור החיפוש שהוקלד</div>
                            )
                    }
                </div>
            </div>
        )
    }
    {
        lastField && relatedSecurities && relatedSecurities[0]?.length > 0 && (
            // true && (
            <div dir='rtl' style={{top: 132, maxHeight: 500, overflow: "auto"}}
                className="search-popup bg-white p-3 ">
                <div className="text-center font-bold">ניירות ערך דומים</div>
                <div className="text-center text-sm">הוסף עד 5 ני”ע דומים להשוואה</div>
                {/*<div className="text-center">Similar Securities</div>*/}
                <div style={{ scrollbarWidth: 'none' }} className="px-1 py-5 overflow-x-scroll lg:overflow-none">
                    <table  className="table table-compact table-zebra  w-full table-hover">
                        <thead className="text-center pt-5">
                        <tr>
                            <th style={{ borderRadius: 0 }} className="text-sm font-semibold text-right px-2 p-1"></th>
                            <th className="text-sm font-semibold text-right px-5 p-1 pr-0">
                                {/*sec_name*/}
                                שם נייר ערך
                            </th>
                            <th
                                style={{display: relatedSecurities[0][0][5] === null ? "none" : "true"}}
                                className="text-sm font-semibold px-5 p-1">
                                {/*sec_type_sub*/}
                                סוג ני”ע
                            </th>
                            <th
                                style={{display: relatedSecurities[0][0][7] === null ? "none" : "true"}}
                                className="text-sm font-semibold px-5 p-1">
                                {/*sector_sub*/}
                                ענף משנה
                            </th>
                            <th
                                style={{display: relatedSecurities[0][0][8] === null ? "none" : "true"}}
                                className="text-sm font-semibold px-5 p-1">
                                {/*atzmada_bonds*/}
                                הצמדה
                            </th>

                            <th
                                style={{display: relatedSecurities[0][0][11] === null ? "none" : "true"}}
                                className="text-sm font-semibold px-5 p-1 ">
                                {/*foreign_rate*/}
                                דירוג
                            </th>
                            <th
                                style={{display: relatedSecurities[0][0][13] === null ? "none" : "true"}}
                                className="text-sm text-right font-semibold px-5 p-1">
                                שווי שוק
                            </th>
                            <th
                                style={{display: relatedSecurities[0][0][15] === null ? "none" : "true"}}
                                className="text-sm font-semibold px-5 p-1">
                                מח”מ
                            </th>

                        </tr>
                        </thead>

                        <tbody className="text-center pt-5">
                        {
                            relatedSecurities[0].map((row, index) => (
                                <tr key={row[0]}>
                                    <td className="px-2 pt-2 pb-0 rounded-none">
                                        <input
                                            // value={index}
                                            type="checkbox"
                                            checked={relatedSecuritySelectedValues[index]}
                                            onChange={() => handleAddRelatedSecurity(index)}
                                            // disabled={checkDisable(num_multi_search, secType)}
                                        />
                                    </td>
                                    <td
                                        onClick={() => addValueFromSimilarSearch(row)}
                                        className="text-sm p-1 px-5 pr-0 text-right cursor-pointer hover:font-bold">
                                        {row[1]}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][5] == null ? "none" : "true"}}
                                        className="text-sm p-1 px-5">
                                        {related_securities_legend['related_securities_sec_type_sub_legend'][row[5]] ? related_securities_legend['related_securities_sec_type_sub_legend'][row[5]] : row[5]}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][7] == null ? "none" : "true"}}
                                        className="text-sm p-1 px-5">
                                        {related_securities_legend['related_securities_sector_sub_legend'][row[7]] ? related_securities_legend['related_securities_sector_sub_legend'][row[7]] : row[7]}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][8] == null ? "none" : "true"}}
                                        className="text-sm p-1 px-5">
                                        {related_securities_legend['related_securities_atzmada_legend'][row[8]] ? related_securities_legend['related_securities_atzmada_legend'][row[8]] : row[8]}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][11] == null ? "none" : "true"}}
                                        className="text-sm p-1 px-5">
                                        {related_securities_legend['related_securities_unify_rating_legend'][row[11]] ? related_securities_legend['related_securities_unify_rating_legend'][row[11]] : row[11]}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][13] == null ? "none" : "true"}}
                                        className="text-sm text-right p-1 px-5">{row[13] ? parseInt(row[13]).toLocaleString('en-US', {maximumFractionDigits: 0}) : ""}
                                    </td>
                                    <td
                                        style={{display: relatedSecurities[0][0][15] == null ? "none" : "true"}}
                                        className="text-sm p-1 px-5">{row[15]}
                                    </td>

                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                
                <button 
                    onClick={handleSearchRelatedSecurity}
                    disabled={
                        (relatedSecuritySelectedValues?.filter(value => value).length === 0 ||
                        relatedSecuritySelectedValues?.filter(value => value).length > 5) 
                        ? true : false
                    }
                    className="popup-btn btn btn-sm text-center ml-[auto] mb-1 rounded-[4px]">
                        הוסף להשוואה
                </button>
            </div>
        )
    }

    {/* ADVANDED SEARCH POPUP */}
    {
        secId.length <= 1 ?
        <div
            className={`bg-white p-3 advanced-search-dropdown rounded w-1/2 w-[100%] lg:max-w-[275px] absolute top-10 z-10 ${activeClass}`}
        >
            <div className="flex justify-between items-start">
                <p
                    onClick={cancel}
                    className="close bg-transparent hover:bg-transparent border-0 text-black"
                >
                    <FaTimes/>
                </p>

                <div className="text-right">
                    <p className="font-semibold">{title_modal}</p>
                    <p className="text-sm">{description}</p>
                    <p className="text-sm mt-3">{description_}</p>
                </div>
            </div>
            {isCustomFilter ?
                <>
                    <div className="mt-4">
                        {customFilters && customFilters['sec_type_sub'] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">sec_type_sub</p>*/}
                                <select dir="rtl" onChange={(e) => filter_change(e, "sec_type_sub")}
                                        className="w-full border py-2 rounded mb-3 "

                                >
                                    <option key="sec_type_sub" value="sec_type_sub"> סוג ני”ע
                                    </option>
                                    {/* <option key="sel">sec_type_sub</option> */}

                                    {/*{*/}
                                    {/*    customFilters['sec_type_sub'].map(*/}
                                    {/*        value => (*/}
                                    {/*            <option value={value.toString()}*/}
                                    {/*                    key={value.toString()}>{customFilters['sec_type_sub_legend'][value] ? customFilters['sec_type_sub_legend'][value] : value}*/}
                                    {/*            </option>*/}
                                    {/*        )*/}
                                    {/*    )   */}
                                    {/*}*/}
                                    {
                                        customFilters['sec_type_sub']?.map(value => ({
                                            value: value,
                                            display: customFilters['sec_type_sub_legend'][value] ? customFilters['sec_type_sub_legend'][value] : value
                                        }))
                                            .sort((a, b) => a.display.localeCompare(b.display))
                                            .map(item => (
                                                <option value={item.value.toString()}
                                                        key={item.value.toString()}>
                                                    {item.display}
                                                </option>
                                            ))
                                    }

                                </select>
                                {/*<input type='text' defaultValue={filters['sec_type_sub'][0]} disabled={false}*/}
                                {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}
                            </>
                        }
                        {customFilters && customFilters['sector_sub']?.length > 0 && customFilters['sector_sub'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">sector sub</p>*/}
                                <select dir="rtl" onChange={(e) => filter_change(e, "sector_sub")}
                                        className="w-full border py-2 rounded mb-3">
                                    <option key="sector_sub" value="sector_sub">ענף משנה</option>
                                    {/*<option key="sel">sector_sub</option>*/}
                                    {/*{customFilters['sector_sub'].map(value => (*/}
                                    {/*    <option value={value.toString()}*/}
                                    {/*            key={value.toString()}>{customFilters['sector_sub_legend'][value] ? customFilters['sector_sub_legend'][value] : value}</option>*/}
                                    {/*))}*/}

                                    {
                                        customFilters['sector_sub']?.map(value => ({
                                            value: value,
                                            display: customFilters['sector_sub_legend'][value] ? customFilters['sector_sub_legend'][value] : value
                                        }))
                                            .sort((a, b) => a.display.localeCompare(b.display))
                                            .map(item => (
                                                <option value={item.value.toString()}
                                                        key={item.value.toString()}>
                                                    {item.display}
                                                </option>
                                            ))
                                    }
                                </select>
                                {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                            </>
                        }

                        {customFilters && customFilters['atzmada'] != null && customFilters['atzmada']?.length > 0 && customFilters['atzmada'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">Atzmada</p>*/}
                                <select dir="rtl" onChange={(e) => filter_change(e, "atzmada")}
                                        className="w-full border py-2 rounded mb-3">
                                    <option key="atzmada" value="atzmada">הצמדה</option>
                                    {/*<option key="sel">atzmada</option>*/}
                                    {customFilters['atzmada'].map(value => (
                                        <option value={value.toString()}
                                                key={value.toString()}>{customFilters['atzmada_legend'][value] ? customFilters['atzmada_legend'][value] : value}
                                        </option>

                                    ))}
                                </select>
                                {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                            </>
                        }

                        {customFilters && customFilters['foreign_rate'] != null && customFilters['foreign_rate']?.length > 0 && customFilters['foreign_rate'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">Atzmada</p>*/}
                                <select dir="rtl" onChange={(e) => filter_change(e, "foreign_rate")}
                                        className="w-full border py-2 rounded mb-3">
                                    {/*<option key="sel">foreign_rate</option>*/}
                                    <option key="foreign_rate" value="foreign_rate">דירוג</option>
                                    {customFilters['foreign_rate'].map(value => (
                                        <option value={value.toString()}
                                                key={value.toString()}>{customFilters['foreign_rate_legend'][value] ? customFilters['foreign_rate_legend'][value] : value}</option>

                                    ))}
                                </select>
                                {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                            </>
                        }
                        {/*{customFilters && customFilters['atzmada'][0] !== null &&*/}
                        {/*    <>*/}
                        {/*        <p className="text-sm mb-3">atzmada</p>*/}
                        {/*        <select disabled className="w-full border py-2 rounded mb-3">*/}
                        {/*            {customFilters['atzmada'].map(value => (*/}
                        {/*                <option>{value}</option>*/}
                        {/*            ))}*/}
                        {/*        </select>*/}
                        {/*        /!*<input type='text' defaultValue={filters['atzmada'][0]} disabled={false}*!/*/}
                        {/*        /!*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*!/*/}
                        {/*    </>*/}
                        {/*}*/}
                        {/*{customFilters && customFilters['rating'][0] !== null &&*/}
                        {/*    <>*/}
                        {/*        <p className="text-sm mb-3">foreign rate</p>*/}
                        {/*        <select className="w-full border py-2 rounded mb-3">*/}
                        {/*            {customFilters['rating'].map(value => (*/}
                        {/*                <option>{value}</option>*/}
                        {/*            ))}*/}
                        {/*        </select>*/}
                        {/*        /!*<input type='text' defaultValue={filters['rating'][0]} disabled={false}*!/*/}
                        {/*        /!*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*!/*/}
                        {/*    </>*/}
                        {/*}*/}
                        {/*<div className="mt-2 mb-10">*/}

                        {/*    {*/}
                        {/*        filters && toShowDuration &&*/}
                        {/*        filters['s_duration_range'][0] !== 0 &&*/}
                        {/*        filters['s_duration_range'][1] !== 0 &&*/}
                        {/*        filters['s_duration_range'][2] !== 0 &&*/}
                        {/*        filters['s_duration_range'][3] !== 0 &&*/}
                        {/*        <>*/}
                        {/*            <p className="text-sm mb-7 text-right font-bold"><span style={onHoverDur ? {*/}
                        {/*                backgroundColor: "#2A2A2A",*/}
                        {/*                color: "#fff"*/}
                        {/*            } : {}}> {formatNumber(filters['actual_dur'])} </span> מח”מ </p>*/}
                        {/*            <MultiRangeSliderSmall*/}
                        {/*                lowerLimit={filters['s_duration_range'][0]}*/}
                        {/*                currentLower={filters['s_duration_range'][1]}*/}
                        {/*                currentUpper={filters['s_duration_range'][2]}*/}
                        {/*                upperLimit={filters['s_duration_range'][3]}*/}
                        {/*                onChange={({min, max}) => {*/}
                        {/*                    setrangeduration(min, max)*/}
                        {/*                }}*/}
                        {/*                actual={filters['actual_dur']}*/}
                        {/*                setOnHover={setOnHoverDur}*/}

                        {/*            />*/}
                        {/*        </>*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="mt-2 mb-10">
                            {
                                customFilters['s_duration_range'] &&
                                customFilters['s_duration_range'].some(value => value !== 0) &&
                                // customFilters['s_duration_range'][0] !== 0 &&
                                // customFilters['s_duration_range'][1] !== 0 &&
                                // customFilters['s_duration_range'][2] !== 0 &&
                                // customFilters['s_duration_range'][3] !== 0 &&
                                <>
                                    <p className="text-sm mb-7 text-right font-bold"><span
                                        style={onHoverDur ? {
                                            backgroundColor: "#2A2A2A",
                                            color: "#fff"
                                        } : {}}> {formatNumber(customFilters['s_duration_range'][0] + customFilters['s_duration_range'][3]) / 2} </span> מח”מ
                                    </p>
                                    <MultiRangeSliderSmall
                                        lowerLimit={customFilters['s_duration_range'][0]}
                                        currentLower={customFilters['s_duration_range'][1]}
                                        currentUpper={customFilters['s_duration_range'][2]}
                                        upperLimit={customFilters['s_duration_range'][3]}
                                        onChange={({min, max}) => {
                                            setrangeduration(min, max)
                                        }}
                                        actual={(customFilters['actual_dur'][0] + customFilters['actual_dur'][3]) / 2}
                                        setOnHover={setOnHoverDur}

                                    />
                                </>
                            }
                        </div>
                        <div className="mt-16 mb-14 ">
                            {
                                customFilters['market_cap_range'] &&
                                customFilters['market_cap_range'][0] !== 0 &&
                                customFilters['market_cap_range'][1] !== 0 &&
                                customFilters['market_cap_range'][2] !== 0 &&
                                customFilters['market_cap_range'][3] !== 0 &&
                                <>
                                    <p className="text-sm mb-7 text-right font-bold"><span
                                        style={onHoverCap ? {
                                            backgroundColor: "#2A2A2A",
                                            color: "#fff"
                                        } : {}}> {formatNumber((customFilters['market_cap_range'][0] + customFilters['market_cap_range'][3]) / 2)}
                                    </span> שווישוק </p>
                                    <MultiRangeSlider
                                        lowerLimit={customFilters['market_cap_range'][0]}
                                        currentLower={customFilters['market_cap_range'][1]}
                                        currentUpper={customFilters['market_cap_range'][2]}
                                        upperLimit={customFilters['market_cap_range'][3]}
                                        onChange={({min, max}) => {
                                            setrangecap_custom(min, max)
                                        }}
                                        actual={(customFilters['market_cap_range'][0] + customFilters['market_cap_range'][3]) / 2}
                                        setOnHover={setOnHoverCap}
                                    />
                                </>
                            }
                        </div>
                        <div className="flex justify-start items-center mt-20">

                            <button
                                className="bg-gray-100 text-black px-3 py-2 ml-3"
                                onClick={cancel}
                            >
                                בטל
                            </button>
                            <button
                                onClick={() => custom_filters_onclick()}
                                className="bg-[#6691E7] text-white px-3 py-2">
                                חפש
                            </button>
                        </div>
                    </div>
                </>
                :
                <>

                    <div className="mt-4">
                        {filters && filters['sec_type_sub'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">sec_type_sub</p>*/}
                                {/*<select className="w-full border py-2 rounded mb-3">*/}
                                {/*    {filters['sec_type_sub'].map(value => (*/}
                                {/*            <option>{value}</option>*/}
                                {/*        )*/}
                                {/*    )}*/}

                                {/*</select>*/}
                                <input type='text' defaultValue={
                                    filters && filters['sec_type_sub_legend'] && filters['sec_type_sub_legend'][filters['sec_type_sub'][0]] ?
                                        filters['sec_type_sub_legend'][filters['sec_type_sub'][0]] : filters['sec_type_sub'][0]
                                    // filters['sec_type_sub'][0]
                                }
                                    disabled={false}
                                    className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                            </>
                        }
                        {filters && filters['sector_sub'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">sector sub</p>*/}
                                {/*<select className="w-full border py-2 rounded mb-3">*/}
                                {/*    {filters['sector_sub'].map(value => (*/}
                                {/*        <option>{value}</option>*/}
                                {/*    ))}*/}
                                {/*</select>*/}
                                <input type='text' defaultValue={
                                    filters && filters['sector_sub_legend'] && filters['sector_sub_legend'][filters['sector_sub'][0]] ?
                                        filters['sector_sub_legend'][filters['sector_sub'][0]] : filters['sector_sub'][0]
                                    // filters['sector_sub'][0]
                                }
                                    disabled={false}
                                    className="w-full border px-2 py-2 rounded mb-3 text-right"/>

                            </>
                        }
                        {filters && filters['atzmada'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">atzmada</p>*/}
                                {/*<select disabled className="w-full border py-2 rounded mb-3">*/}
                                {/*    {filters['atzmada'].map(value => (*/}
                                {/*        <option>{value}</option>*/}
                                {/*    ))}*/}
                                {/*</select>*/}
                                <input type='text' defaultValue={

                                    filters && filters['atzmada_legend'] &&  filters['atzmada_legend'][filters['atzmada'][0]] ?
                                        filters['atzmada_legend'][filters['atzmada'][0]] : filters['atzmada'][0]
                                }

                                    disabled={false}
                                    className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                            </>
                        }
                        {filters && filters['rating'][0] !== null &&
                            <>
                                {/*<p className="text-sm mb-3">foreign rate</p>*/}
                                {/*<select className="w-full border py-2 rounded mb-3">*/}
                                {/*    {filters['rating'].map(value => (*/}
                                {/*        <option>{value}</option>*/}
                                {/*    ))}*/}
                                {/*</select>*/}
                                <input type='text' defaultValue={
                                    filters && filters['foreign_rate_legend'] && filters['foreign_rate_legend'][filters['rating'][0]] ?
                                        filters['foreign_rate_legend'][filters['rating'][0]] : filters['rating'][0]
                                } disabled={false}
                                    className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                            </>
                        }
                        <div className="mt-2 mb-10">

                            {
                                filters && toShowDuration &&
                                filters['s_duration_range'].some(value => value !== 0) &&
                                // filters['s_duration_range'][0] !== 0 &&
                                // filters['s_duration_range'][1] !== 0 &&
                                // filters['s_duration_range'][2] !== 0 &&
                                // filters['s_duration_range'][3] !== 0 &&
                                <>
                                    <p className="text-sm mb-7 text-right font-bold"><span
                                        style={onHoverDur ? {
                                            backgroundColor: "#2A2A2A",
                                            color: "#fff"
                                        } : {}}> {formatNumber(filters['actual_dur'])} </span> מח”מ </p>
                                    <MultiRangeSliderSmall
                                        lowerLimit={filters['s_duration_range'][0]}
                                        currentLower={filters['s_duration_range'][1]}
                                        currentUpper={filters['s_duration_range'][2]}
                                        upperLimit={filters['s_duration_range'][3]}
                                        onChange={({min, max}) => {
                                            setrangeduration(min, max)
                                        }}
                                        actual={filters['actual_dur']}
                                        setOnHover={setOnHoverDur}

                                    />
                                </>
                            }
                        </div>
                        <div className="mt-16 mb-14 ">
                            {
                                filters && toShowCap &&
                                filters['market_cap_range'][0] !== 0 &&
                                filters['market_cap_range'][1] !== 0 &&
                                filters['market_cap_range'][2] !== 0 &&
                                filters['market_cap_range'][3] !== 0 &&
                                <>
                                    <p className="text-sm mb-7 text-right font-bold"><span
                                        style={onHoverCap ? {
                                            backgroundColor: "#2A2A2A",
                                            color: "#fff"
                                        } : {}}> {formatNumber(filters['actual_cap'])} </span> שווי שוק
                                    </p>
                                    <MultiRangeSlider
                                        lowerLimit={filters['market_cap_range'][0]}
                                        currentLower={filters['market_cap_range'][1]}
                                        currentUpper={filters['market_cap_range'][2]}
                                        upperLimit={filters['market_cap_range'][3]}
                                        onChange={({min, max}) => {
                                            setrangecap(min, max)
                                        }}
                                        actual={filters['actual_cap']}
                                        setOnHover={setOnHoverCap}
                                    />
                                </>
                            }
                        </div>
                        <div className="flex justify-start items-center mt-20">

                            <button
                                className="bg-gray-100 text-black px-3 py-2 ml-3"
                                onClick={cancel}
                            >
                                בטל
                            </button>
                            <button
                                onClick={() => applyFilters()}
                                className="bg-[#6691E7] text-white px-3 py-2">
                                חפש
                            </button>
                        </div>
                    </div>
                </>
            }
        </div>
        : null
    }
  </div>
  )
}

export default SearchComponent;