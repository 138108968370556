import React from 'react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    // You might want to log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);

    // Directly reload the app
    // window.location.reload();
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
