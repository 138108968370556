import React from 'react';
import './Policy.css';
import { useNavigate } from 'react-router-dom';

const Policy = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/login');
  }
  return (
    <div dir='rtl' className='policy'>
      <div className='policy-container'>
        <h1>תנאי שימוש</h1>
        <br />
        <p>ברוכים הבאים לאתר ברוקרס קונקט! לפני השימוש באתר, אנא קרא את תנאי השימוש בקפידה.</p>
        <p>תנאים אלה מחייבים את כל המשתמשים באתר.</p>
        <br />

        <h2>השימוש באתר:</h2>
        <ul>
          <li>כניסה לאתר ו/או שימוש בשירותיו מהווה הסכמה מפורשת לתנאי השימוש.</li>
          <li>תנאי השימוש עשויים להשתנות מעת לעת, ועל כן מומלץ לעיין בהם באופן קבוע.</li>
          <li>תנאי השימוש מנוסחים בלשון זכר מטעמי נוחות בלבד.</li>
          <li>חלוקת התנאים לסעיפים וכותרות נועדה לנוחות בלבד ואינה משפיעה על פרשנותם.</li>
        </ul>
        <br />

        <h2>תנאים נוספים:</h2>
        <ul>
          <li>האתר רשאי לשנות את השירותים והמוצרים המוצעים בו בכל עת.</li>
          <li>האתר רשאי לחסום את גישתך לאתר מכל סיבה שהיא לרבות עקב שימוש שאינו ראוי ומפר את תנאי האתר.</li>
        </ul>
        <br />

        <h2>הצהרתך:</h2>
        <ul>
          <li>הנך מצהיר כי קראת את תנאי השימוש ומסכים להם במלואם.</li>
          <li>הנך מצהיר כי תשתמש באתר באופן חוקי ותוך שמירה על כללים אלו.</li>
        </ul>
        <br />

        <ol>
          {/* 1 - 5 */}
          <li>אתר ברוקרס קונקט מספק מידע אודות שוק ההון בישראל, האתר מציע שירותים ללא תשלום ובתשלום וכן יכול לשנות מ"ללא תשלום" ל"תשלום" בכל עת ללא הודעה מוקדמת.</li>
          <li>הגישה לאתר הינה אישית באמצעות שם משתמש וסיסמה. הגולש מתחייב שלא להשתמש בקוד הסודי של אחר ושלא למסור את הקוד הסודי שלו לאחר.</li>
          <li>האתר עשוי להציע הצעות בעלות אופן מסחרי באמצעות אי-מייל, מסרונים ודרכי התקשרות נוספים העומדים לרשותו לרבות אפליקציות צד שלישי וכן שליחת מידע, דיוורים ועדכונים משתנים מעת לעת. ניתן יהיה להיגרע מרשימת התפוצה באמצעות לינק ייעודי באי מייל, ומסרונים.</li>
          <li>לאתר שמורה הזכות לשנות את המידע המוצג בו, את מבנהו, את השירותים הניתנים בו, את תנאי השימוש ואף להפסיק את פעילותו באופן מלא או חלקי. כל שינוי כאמור יחייב את המשתמשים מרגע פרסומו באתר, או מרגע קבלת הודעה על כך בדרך אחרת. מומלץ לעיין בנוסח תנאי השימוש מעת לעת</li>
          <li>התוכן באתר, הגישה אליו והשימוש בו כפופים לחוקי מדינת ישראל בלבד. בכל מקרה של סכסוך הנוגע לתנאי השימוש באתר, לרבות מדיניות הפרטיות, סמכות השיפוט הבלעדית תהיה לבתי המשפט המוסמכים במחוז תל אביב-יפו.</li>
          
          {/* 6 - 10 */}
          <li>אין להעתיק ו/או לשכפל את המידע או כל חלק ממנו לצורך הפצה או פרסום בדרך כלשהי, לרבות לרבות הדפסה, צילום, צילום מסך, העברה אלקטרונית או שיתוף ברשתות חברתיות ללא הסכמה מפורשת ובכתב מהנהלת האתר.</li>
          <li>ברוקרס קונקט רשאית להגן על זכויותיה בנוגע לתכנים באתר בכל דרך שתמצא לנכון. אי נקיטת פעולה מצידה כנגד הפרה של זכויותיה לא תיחשב כוויתור על זכויות אלה..</li>
          <li>האתר אוסף את המידע ממספר מקורות לרבות אתרי מנהלי ההשקעות ועושה כמיטב יכולתו להציג את המידע העדכני והמדויק ביותר, מנגד האתר אינו מתחייב לשלמות במידע, נכונות המידע ואינו אחראי לכול ליקוי, טעות, שגיאה, השמטה או אי-דיוק שנפלו באתר ו/או בשירותים המוצעים בו, מכול סיבה שהיא.</li>
          <li>השימוש במידע באתר הינו באחריות המשתמש ולמשתמש לא תהיה כל עילה כנגד האתר במידה ונתגלו טעויות במידע.</li>
          <li>המידע באתר אינו המלצה, הבעת דעה או הנעה לפעולה לרכישת ניירות ערך או עצה לנהוג בדרך זו או אחרת בכל הקשור לקבלת החלטות בתחום ההשקעות ואינו נועד להוות תחליף להתייעצות עם מומחים. המשתמש יישא במלוא האחריות בגין קבלת החלטות השקעה או כל החלטה אחרת על סמך השירותים המוצעים</li>

          {/* 11 - 15 */}
          <li>בעת הגלישה באתר עשויים להופיע קישורים לצדדים שלישיים הכוללים מידע נוסף, שירותים ומודעות פרסומיות. פתיחת הקישורים יובילו לאתרים בבעלות צדדים שלישיים, יובהר כי ברוקרס קונקט אינה אחראית למידע או התכנים אשר יוצגו באתרים אלו ואינה מתחייבת כי כל הקישורים יהיו תקינים, אינה אחראית למדיניות הפרטיות ואבטחת המידע של אתרים אלה ולא תהא אחראית לכל נזק ישיר או עקיף, שייגרם לך כתוצאה מהשימוש באתרים אלה. טענות ככל שיהיו הקשורות לתכני הצד השלישי, יופנו לצד השלישי הרלוונטי.</li>
          <li>האתר פועל על בסיס טכנולוגיות ענן וטכנולוגיות של צדדים שלישיים וקיימת תלות בצדדים שלישיים לפעילותו התקינה לרבות זמינות ותקינות של רשת האינטרנט וכן ציוד הקצה שברשות הגולש. תקלות בצדדים שלישיים עשויים לגרור לשיבוש פעילותו התקינה של האתר. לפיכך האתר אינו מתחייב לגישה רציפה ותקינה בשל תקלות אשר מקורם בצדדים שלישיים ואינם בשליטתו. האתר לא יהיה אחראי לכל נזק, ישיר או עקיף, עגמת נפש וכיו"ב שיגרמו לגולש ו/או למי מטעמו עקב כך.</li>
          <li>. האתר עושה מאמצים מרבים כדי לשמור על גלישה בטוחה, אך לא ניתן להבטיח באופן מוחלט את חסינות המערכות מפני ניסיונות חדירה או הפרעות לפעילות האתר על ידי גורמים חיצוניים. במקרים מעין אלה ברוקרס קונקט אינה אחראית לנזקים שעלולים להתרחש, לאחר שננקטו אמצעים מקובלים ונדרשים לאבטחת המידע.</li>
          <li>הגולש מתחייב לגלוש באתר בהתאם לכל דין ולא לבצע כל פעולת חדירה ו/או שיבוש ו/או לסייע בפעולות כאלה וכן לא לעשות שימוש במערכות סריקה והורדת נתונים באמצעות בוטים או מערכות דומות.</li>
          <li>השימוש באתר ובשירותים לא מעניק לגולש זכויות כלשהן באתר או בשירותים, אלא אם נצוין במפורש אחרת.</li> 
        </ol>
        <br />
        <br />

        <p>למידע נוסף צור קשר במייל info@brokersconnect.co.il</p>
        
        <button
            type="button"
            onClick={handleBack}
            className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
        >
            Login
        </button>
      </div>
    </div>
  );
}

export default Policy;