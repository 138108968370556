import React, { useEffect, useRef, useState } from "react";
import { FaFileExcel } from "react-icons/fa";

const RelativeSearchDropdown = ({
  visibleRelativeCorpIdCount,
  relatedCorpsIds,
  handleRelatedCorpID,
  handleRelatedCorpIDMultiSearch,
  isMobile
}) => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState("שווי שוק");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectRef = useRef(null);
  

  useEffect(() => {
    document.addEventListener('mousedown', customBlur, { capture: true });
  }, [])
  
  // useEffect(() => {
  // }, [visibleRelativeCorpIdCount])

  const customBlur = (e) => {
    if (
      e.target === selectRef.current || selectRef.current?.contains(e.target)
    ) {
    } else {
      setIsDropdownOpen(false);
    }
    document.addEventListener('mousedown', customBlur, { capture: true });
  }

  const handleSelect = (value) => {
    setCurrentValue(value);
    // handleCustomDropdownTop(value);
    setIsDropdownOpen(false);
  }

  // useEffect(() => {
  // }, [visibleRelativeCorpIdCount])

  if (relatedCorpsIds?.length <= visibleRelativeCorpIdCount - 1 || relatedCorpsIds?.length === 0) {
    return null;
  }
  
  
  return (  
    <div className="relative-search-wrapper"
      onClick={toggleDropdown}
      ref={selectRef}
    >
      <span className="dropdown-icon"></span>
      {isDropdownOpen && (
        <ul dir="rtl" style={{ scrollbarWidth: "none"}} className="z-[9998] max-h-[75vh] bg-[#FFFFFF] lg:bg-[#2A2A2A] left-[16px] overflow-y-scroll overflow-x-hidden absolute rounded:md lg:rounded-none w-[calc(100vw-32px)] lg:w-[270px] rounded-sm z-[1] mt-4">
          {relatedCorpsIds?.map((row, index) => (
            index <= visibleRelativeCorpIdCount - 1 ? null :
            <li dir="rtl"
                key={index}
                className="flex items-center mx-3 text-right text-[#2A2A2A] font-medium lg:text-white cursor-pointer related_corps"
                style={{
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    padding: 4,
                    marginTop: 8,
                }} // Add position relative for tooltip positioning
            >
                <a
                  onContextMenu={(event) => {
                    if (isMobile) {
                      return null;
                    } else {
                      event.preventDefault();
                      handleRelatedCorpIDMultiSearch(index);
                    }
                  }}
                  onClick={() => isMobile ? null : handleRelatedCorpID(index)}
                >{row[0]}</a>
                {isMobile ?
                <>
                  <a
                    type="button"
                    onClick={() => handleRelatedCorpID(index)}
                    className="text-[#1C4FAC] p-2 mr-[auto]"
                  >
                    חיפוש חדש
                    {/* Open new search */}
                  </a>
                  <a
                    type="button"
                    onClick={() => handleRelatedCorpIDMultiSearch(index)}
                    className="text-[#1C4FAC] p-2"
                  >
                    הוסף להשוואה
                    {/* Add to multiple search */}
                  </a>
                </>: null}

                <span className="hidden lg:block tooltip">
                  <span className="tooltip-arrow"></span>
                  <span className="tooltip-text">קליק ימני - הוסף להשוואה</span>
                  {/* <span className="tooltip-text">Right click for multiple search</span> */}
                  <br/>
                  {/*<span className="tooltip-text">Left click for new search</span>*/}
                  <span className="tooltip-text">קליק שמאלי - חיפוש חדש</span>
                </span>
            </li>
          ))}
          {/* <button
            className="dropdown-content w-[100%] p-2 shadow bg-black text-right flex items-center justify-between"
          >
            <span>הורד הכל לאקסל</span>
            <FaFileExcel />
          </button> */}
        </ul>
      )}
    </div>
  )
};

export default RelativeSearchDropdown;


{/* <li key={index}>
  <button
    className={`dropdown-content menu w-[100%] p-2 shadow bg-black text-right ${currentValue === item.value ? "active" : ""}`}
    onClick={() => handleSelect(item.value)}
    onBlur={() => {console.log('onblurr')}}
  >
    <span>{item.value}</span>
  </button>
</li> */}



